import { useSelector } from "react-redux";

import axios, { AxiosInstance } from "axios";
import { endpoints } from "./Endpoints";
import { RootState } from "../types/GlobalTypes";

export const authenticationApi = axios.create({
  baseURL: endpoints.baseURL,
});

//With header(token)
export function useAuthorizedApi(): AxiosInstance {
  const token = useSelector((state: RootState) => state.authentication.token);

  const api = axios.create({
    baseURL: endpoints.baseURL,
    headers: {
      token: `${token}`,
    },
  });

  api.defaults.headers.common["Content-Type"] = "application/json";

  return api;
}
