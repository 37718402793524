import { useDispatch } from "react-redux";
import "../styles/AutoLogout.scss";
import { setToken } from "../store/reducers/AuthReducer";
/**
 * A React functional component that handles auto logout functionality.
 * When the user's sessio n expires, this component is displayed with a "Ok" button which automatically logs the user out.
 */
function AutoLogout(): JSX.Element {
  const dispatch = useDispatch();

  return (
    <div className="auto-logout-main-container">
      <p>Your session is expired</p>
      <button
        onClick={() => {
          dispatch(setToken(null));
          localStorage.clear();
          window.location.reload();
        }}
      >
        Ok
      </button>
    </div>
  );
}

export default AutoLogout;
