import "../styles/Modal.scss";
import ReactDOM from "react-dom";
import { ReactNode } from "react";

interface props {
  children: ReactNode;
  onClose?: () => void;
}
function Modal({ children, onClose }: props) {
  //close modal when user clicks outside the modal
  function handleOverlayClick(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === event.currentTarget) {
      if (onClose) {
        onClose();
      }
    }
  }

  return ReactDOM.createPortal(
    <div className="modal-main-container" onClick={handleOverlayClick} data-testid="modal-overlay">
      {children}
    </div>,
    document.getElementById("modal-root")!
  );
}

export default Modal;
