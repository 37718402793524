import "../styles/WhoWeAre.scss";
import whoWeAreImage from "../../../assets/images/IoT-and-blockchain.svg";

function WhoWeAre() {
  return (
    <article className="who-we-are-main-container">
      <section className="who-we-are-description">
        <h1>Who We Are</h1>
        <p>
          We are a leading research laboratory at Kwame Nkrumah University of
          Science and Technology (KNUST). At DIPPER, we harness the
          transformative potential of technology to tackle diverse challenges.
          Our expert team is committed to pioneering research, particularly in
          integrating state-of-the-art technologies like distributed ledger,
          IoT, edge intelligence, and AI.
        </p>
      </section>
      <div
        className="who-we-are-image-container"
        style={{ display: window.innerWidth < 500 ? "none" : "block" }}
      >
        <img src={whoWeAreImage} alt="iot and blockchain" loading="lazy" />
      </div>
    </article>
  );
}

export default WhoWeAre;
