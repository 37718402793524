import "../styles/Navigation.scss";
import dipperLabLogo from "../assets/images/dipper-lab-logo.png";
import { NavLink, Link } from "react-router-dom";
import useResponsiveFunction from "../utilities/SmallScreen";
import { useState } from "react";
import menu from "../assets/icons/menu.png";
import { AnimatePresence, motion } from "framer-motion";
/**
 * A component that renders the navigation menu for the website.
 * Depending on the screen size, it either renders a normal navigation menu or
 * a smaller screen navigation menu with a button to toggle the menu.
 * @returns {JSX.Element}
 */
function Navigation() {

  const navigationButtons = [
    {
      buttonName: "Home",
      link: "/",
    },
    {
      buttonName: "About Us",
      link: "/about-us",
    },
    {
      buttonName: "Projects",
      link: "/projects",
    },
    {
      buttonName: "Publications",
      link: "/publications",
    },
    {
      buttonName: "Members",
      link: "/labmembers",
    },
    {
      buttonName: "Events",
      link: "/events",
    },
    {
      buttonName: "Contact Us",
      link: "/contact-us",
    },
  ];

  //Small screen navigation
  const [openNavigationMenu, setOpenNavigationMenu] = useState<boolean>(false);
  const { isSmallScreen } = useResponsiveFunction();
  return (
    <>
      <section className="navigation-main-container">
        <div className="navigation-logo">
          <Link to="/">
            <img src={dipperLabLogo} alt="dipper lab logo" />
          </Link>
        </div>
        {isSmallScreen ? (
          <>
            <AnimatePresence>
              {openNavigationMenu && (
                <motion.nav
                  className="navigation-menu"
                  initial={{ opacity: 0, x: "100% " }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, x: "100%" }}
                  data-testid="navigation-menu"
                >
                  <div className="close-container">
                    <button onClick={() => setOpenNavigationMenu(false)}>
                      Close
                    </button>
                  </div>
                  <motion.div
                    className="navigation-menu-buttons-container"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 1 }}
                  >
                    {navigationButtons.map((button, index) => {
                      return (
                        <motion.div
                          whileHover={{
                            scale: 1.05,
                          }}
                          key={index}
                          onClick={() => setOpenNavigationMenu(false)}
                          transition={{
                            duration: 0.3,
                            ease: "easeInOut",
                          }}
                        >
                          <NavLink to={button.link}>
                            <button>{button.buttonName}</button>
                          </NavLink>
                        </motion.div>
                      );
                    })}
                  </motion.div>
                </motion.nav>
              )}
            </AnimatePresence>
            {!openNavigationMenu && (
              <>
                <div
                  className="smaller-screen-nav-button"
                  onClick={() => setOpenNavigationMenu(true)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={menu} alt="menu-button" />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <nav className="navigation-main-container">
              {navigationButtons.map((navigation, index) => {
                return (
                  <NavLink to={navigation.link} key={index}>
                    <button>{navigation.buttonName}</button>
                  </NavLink>
                );
              })}
            </nav>
          </>
        )}
      </section>
    </>
  );
}

export default Navigation;
