import "../styles/AboutUsDescription.scss";

function AboutUsDescription() {

  return (
    <>
      <section className="about-us-description-main-container">
        <h1>About Us</h1>
        <p>
          DIPPER Lab is a cutting-edge research facility located in KNUST,
          Ghana, dedicated to advancing the use of IoT, blockchain, and
          edge-intelligence computing to drive innovation in the food and
          agriculture industries. To support digitization initiatives in Ghana
          and the sub-region, the DIPPER Lab has been set up with a focus on
          engaging, educating, and cultivating local technology ecosystems while
          making blockchain and the Internet of Things (IoT), a central pillar
          of the computing ecosystems across this region. The Lab supports
          students, researchers, faculty, developers, and community-affiliated
          entrepreneurs that seek to design, build, and deploy local technology
          solutions using blockchain and IoT.
        </p>
      </section>
    </>
  );
}

export default AboutUsDescription;
