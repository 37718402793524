import { motion } from "framer-motion";
import "../styles/Notification.scss";

interface Props {
  message: string;
  errorNotification: boolean;
}

/**
 * A React Component that displays a notification message. The message is a
 * prop, and the color of the notification is determined by the errorNotification
 * prop. If errorNotification is true, the notification is red; otherwise, it is
 * green.
 *
 * The notification is animated to slide in from the top of the screen when it is
 * rendered, and to slide out when it is removed. These animations are created with framer-motion.
 */

function Notification({ message, errorNotification }: Props) {
  return (
    <>
      <motion.div
        className="show-notification-main"
        variants={{
          hidden: { opacity: 0, y: -25 },
          visible: { opacity: 1, y: 25 },
        }}
        animate={"visible"}
        transition={{ duration: 0.3 }}
        exit={"hidden"}
      >
        <span
          style={{
            backgroundColor: errorNotification ? "#ffcdcd" : "#cdffcd",
            borderLeft: errorNotification
              ? "10px solid red"
              : "10px solid green",
          }}
        >
          {message}
        </span>
      </motion.div>
    </>
  );
}

export default Notification;
