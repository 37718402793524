import "../styles/AdminLoginPage.scss";
import logo from "../../../assets/images/dipper-lab-logo.png";
import { useState, useEffect } from "react";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setIsLoggedIn, setToken } from "../../../store/reducers/AuthReducer";
import { authenticationApi } from "../../../services/Axios";
import {
  setErrorNotification,
  setShowNotification,
  setNotificationMessage,
} from "../../../store/reducers/AdminNotificationReducer";
import { endpoints } from "../../../services/Endpoints";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
function AdminLoginPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Input fields
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  async function submitLoginDetails(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(
        !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        "Please type a valid email"
      ) ||
      validation(
        password.length < 6,
        "Password should be at least 6 characters"
      )
    ) {
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    const expirationTime = new Date().getTime() + 1440 * 60 * 1000;
    try {
      setIsLoading(true);
      const response = await authenticationApi.post(endpoints.adminLogin, data);
      setIsLoading(false);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("expiredTime", expirationTime.toString());
      dispatch(setToken(response.data.token));
      if (localStorage.getItem("token") === response.data.token) {
        dispatch(setIsLoggedIn(true));
      }
      navigate("/");
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else if (error.response) {
        dispatch(setNotificationMessage("Login Failed"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <div className="admin-login-page-main">
      <AnimatePresence>
        {showNotification && (
          <Notification
            errorNotification={errorNotification}
            message={notificationMessage}
          />
        )}
      </AnimatePresence>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      {showErrorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      <div className="admin-login-header">
        <span>Welcome</span>
        <span>Please enter your details to log in</span>
      </div>
      <div className="admin-login-form">
        <form onSubmit={submitLoginDetails}>
          <input
            type="email"
            placeholder="Enter your email..."
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
              setShowErrorMessage(false);
            }}
          />
          <input
            type="password"
            placeholder="Enter your password..."
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
              setShowErrorMessage(false);
            }}
          />
          <div className="forgot-password-container">
            <Link to={"/admin-forgot-password"}>Forgot Password?</Link>
          </div>
          {isLoading ? <button>Logging in...</button> : <button>Login</button>}
        </form>
      </div>
    </div>
  );
}

export default AdminLoginPage;
