import { useRef, useReducer, useState, useEffect } from "react";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import camera from "../../../assets/icons/camera.png";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import ErrorMessage from "../../../components/ErrorMessage";
import testImage from "../../../assets/images/default-image.jpg";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import SelectFields from "../../../components/SelectFields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
type ProjectType = {
  title: string;
  funding: string;
  currency: string;
  startDate: string;
  endDate: string;
  description: string;
  nonLabContributers: string;
};
function projectReducer(state: ProjectType, action: any): ProjectType {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "funding":
      return {
        ...state,
        funding: action.payload,
      };
    case "currency":
      return {
        ...state,
        currency: action.payload,
      };
    case "startDate":
      return {
        ...state,
        startDate: action.payload,
      };
    case "endDate":
      return {
        ...state,
        endDate: action.payload,
      };
    case "description":
      return {
        ...state,
        description: action.payload,
      };
    case "nonLabContributers":
      return {
        ...state,
        nonLabContributers: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function AddProject() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sponsors, setSponsors] = useState<LabMemberTypes[]>([]);
  const [contributers, setContributers] = useState<LabMemberTypes[]>([]);

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //project reducer
  const projectInitialState: ProjectType = {
    title: "",
    funding: "",
    currency: "",
    startDate: "",
    endDate: "",
    description: "",
    nonLabContributers: "",
  };

  const [projectState, dispatchProject] = useReducer(
    projectReducer,
    projectInitialState
  );

  //Projects inputs array
  const addProjectInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: projectState.title,
    },
    {
      placeHolder: "Start date",
      type: "text",
      mainType: "startDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: projectState.startDate,
    },
    {
      placeHolder: "End date",
      type: "text",
      mainType: "endDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: projectState.endDate,
    },
    {
      placeHolder: "Funding",
      type: "text",
      mainType: "funding",
      value: projectState.funding,
    },
    {
      placeHolder: "Currency",
      type: "text",
      mainType: "currency",
      value: projectState.currency,
    },
    {
      placeHolder: "Non-Lab Contributors. Seperate with a comma ( , )",
      type: "text",
      mainType: "nonLabContributers",
      value: projectState.nonLabContributers,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const inputFileRef = useRef<HTMLInputElement>(null);

  //Submit project details
  const api = useAuthorizedApi();
  // console.log(authenticationApi);
  async function submitProjectDetails(event: React.FormEvent<HTMLFormElement>) {
    const currencySymbols = ["$", "€", "£", "¥", "₹", "₵", "GHS"];

    event.preventDefault();
    if (
      validation(image === testImage, "Please upload an image") ||
      validation(
        projectState.title.length < 2,
        "Title should be more than 2 characters"
      ) ||
      validation(
        projectState.startDate.length === 0,
        "Start date is required"
      ) ||
      validation(projectState.endDate.length === 0, "End date is required") ||
      validation(
        projectState.funding.length < 1,
        "Funding is required. If the project is not funded, type 0"
      ) ||
      validation(
        !currencySymbols.includes(projectState.currency) &&
          projectState.funding !== "0",
        "Please type a valid currency symbol ($, €, £, ¥, ₹, ₵, GHS)"
      ) ||
      validation(
        isNaN(Number(projectState.funding)),
        "Funding must be a number"
      ) ||
      validation(
        contributers.length === 0 &&
          projectState.nonLabContributers.length === 0,
        "A contributer is required"
      ) ||
      validation(
        projectState.description.length < 3,
        "Description is must be at least 3 characters"
      )
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const uploadedUrl = await handleCloudinaryUpload(image);

      const data = {
        title: projectState.title,
        funding: Number(projectState.funding),
        currency: projectState.currency,
        startDate: projectState.startDate,
        endDate: projectState.endDate,
        description: projectState.description,
        image: uploadedUrl,
        contributors: contributers.map((contributer) => {
          return { id: contributer.id };
        }),
        sponsors: sponsors.map((sponsor) => {
          return { id: sponsor.id };
        }),
        nonLabContributors: projectState.nonLabContributers.split(","),
      };

      await api.post(endpoints.addProject, data);
      setImage(testImage);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(setNotificationMessage("Project added successfully"));
      setIsLoading(false);
      dispatchProject({ type: "reset", payload: projectInitialState });
      setShowErrorMessage(false);
      setSponsors([]);
      setContributers([]);
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage("Failed to add project"));
      } else if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Add Project</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div className="inputs-card-image">
                <img
                  src={image}
                  alt={"lab-member"}
                  style={{
                    height: "190px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "9rem", bottom: "0rem" }}
              >
                <button
                  onClick={() => {
                    inputFileRef.current?.click();
                    setShowErrorMessage(false);
                  }}
                >
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                ref={inputFileRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
              />
            </div>
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitProjectDetails}>
            {addProjectInputs.map((input, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchProject({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  onFocus={input.focus}
                  onBlur={input.blur}
                  value={input.value}
                />
              );
            })}
            <SelectFields
              selectCaption="Select contributors..."
              participantsArray={contributers}
              setParticipantsArray={setContributers}
              sponsorsOrMembersArray="lab-members"
            />
            <SelectFields
              selectCaption="Select sponsors..."
              participantsArray={sponsors}
              setParticipantsArray={setSponsors}
              sponsorsOrMembersArray="sponsors"
            />
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchProject({
                  type: "description",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={projectState.description}
            />
            {isLoading ? (
              <button className="add-button">Adding...</button>
            ) : (
              <button className="add-button">Add Project</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddProject;
