import { createSlice } from "@reduxjs/toolkit";

export type AdminButtonsReducerState = {
  openAddProject: boolean;
  openAddPublication: boolean;
  openAddMember: boolean;
  openAddEvent: boolean;
  openAddSponsor: boolean;
};

const initialState: AdminButtonsReducerState = {
  openAddProject: false,
  openAddPublication: false,
  openAddMember: false,
  openAddEvent: false,
  openAddSponsor: false,
};

const adminButtonsSlice = createSlice({
  name: "admin-buttons",
  initialState,
  reducers: {
    setOpenAddProject: (state, action) => {
      state.openAddProject = action.payload;
    },
    setOpenAddPublication: (state, action) => {
      state.openAddPublication = action.payload;
    },
    setOpenAddMember: (state, action) => {
      state.openAddMember = action.payload;
    },
    setOpenAddEvent: (state, action) => {
      state.openAddEvent = action.payload;
    },
    setOpenAddSponsor: (state, action) => {
      state.openAddSponsor = action.payload;
    },
  },
});

export const {
  setOpenAddEvent,
  setOpenAddMember,
  setOpenAddPublication,
  setOpenAddProject,
  setOpenAddSponsor,
} = adminButtonsSlice.actions;
export default adminButtonsSlice.reducer;
