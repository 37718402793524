import { useRef, useReducer, useState, useEffect } from "react";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import camera from "../../../assets/icons/camera.png";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import ErrorMessage from "../../../components/ErrorMessage";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import SelectFields from "../../../components/SelectFields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { useEditedData } from "../../../helpers/EdittedData";
import { PublicationType } from "../../Publication Page/components/Publications";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";

interface Props {
  publicationDetails: PublicationType | null;
}
function editPublicationReducer(
  state: PublicationType,
  action: any
): PublicationType {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "abstract":
      return {
        ...state,
        abstract: action.payload,
      };
    case "link":
      return {
        ...state,
        link: action.payload,
      };
    case "publicationDate":
      return {
        ...state,
        publicationDate: action.payload,
      };
    case "nonLabAuthors":
      return {
        ...state,
        non_lab_authors: action.payload
          .split(",")
          .map((author: string) => author.trim()),
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function EditPublication({ publicationDetails }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authors, setAuthors] = useState<LabMemberTypes[]>(
    publicationDetails?.authors || []
  );

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //formatted dates
  const formattedPublishedDate = publicationDetails?.publicationDate
    ? new Date(publicationDetails?.publicationDate).toDateString()
    : "";
  //project reducer
  const editPublicationInitialState: PublicationType = {
    title: publicationDetails?.title || "",
    abstract: publicationDetails?.abstract || "",
    link: publicationDetails?.link || "",
    publicationDate: formattedPublishedDate || "",
    non_lab_authors: publicationDetails?.non_lab_authors || [""],
  };

  const [editPublicationState, dispatchEditPublication] = useReducer(
    editPublicationReducer,
    editPublicationInitialState
  );
  //Projects inputs array
  const editPublicationInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: editPublicationState.title,
    },
    {
      placeHolder: "Source link",
      type: "text",
      mainType: "link",
      value: editPublicationState.link,
    },
    {
      placeHolder: "Published date",
      type: "text",
      mainType: "publicationDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: editPublicationState.publicationDate,
    },
    {
      placeHolder: "Non-Lab authors. Seperate with a comma ( , )",
      type: "text",
      mainType: "nonLabAuthors",
      value: editPublicationState.non_lab_authors,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const inputFileRef = useRef<HTMLInputElement>(null);

  //Submit project details
  const api = useAuthorizedApi();

  const originalData = {
    image: publicationDetails?.image || "",
    title: publicationDetails?.title,
    abstract: publicationDetails?.abstract,
    link: publicationDetails?.link,
    publicationDate: publicationDetails?.publicationDate,
    nonLabAuthors: publicationDetails?.non_lab_authors,
    authors: publicationDetails?.authors?.map((author) => {
      return { id: author.id };
    }),
  };
  const newData = {
    title: editPublicationState.title,
    abstract: editPublicationState.abstract,
    link: editPublicationState.link,
    publicationDate: editPublicationState.publicationDate,
    nonLabAuthors: editPublicationState.non_lab_authors,
    image: image,
    authors: authors.map((author) => {
      return { id: author.id };
    }),
  };

  // Initialize image state with the existing publication image
  useEffect(() => {
    if (publicationDetails?.image) {
      setImage(publicationDetails.image);
    }
  }, [publicationDetails, setImage]);

  const { getChangedData } = useEditedData();
  const editedData = getChangedData(originalData, newData);

  async function submitEdittedPublicationDetails(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (
      validation(
        editPublicationState.title.length < 3,
        "Title should be at least 3 characters"
      ) ||
      validation(
        editPublicationState.publicationDate.length === 0,
        "Please input a date"
      ) ||
      validation(
        !editPublicationState.link.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please input a valid link"
      ) ||
      validation(
        authors.length === 0 &&
          editPublicationState.non_lab_authors.length === 0,
        "An author is required"
      ) ||
      validation(
        editPublicationState.abstract.length < 3,
        "Abstract should be at least 3 characters"
      )
    ) {
      return;
    }

    if (Object.keys(editedData).length > 0) {
      try {
        setIsLoading(true);
        let imageUrl: string | null = originalData.image;
        if (image !== originalData.image) {
          imageUrl = await handleCloudinaryUpload(image);
          if (imageUrl) {
            editedData.image = imageUrl;
          }
        }
        await api.patch(
          endpoints.editPublication(publicationDetails?.id),
          editedData
        );
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(false));
        dispatch(setNotificationMessage("Publication edited successfully"));
      } catch (error: any) {
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage(error.response.data.message));
        } else {
          dispatch(setNotificationMessage("Failed to edit publication"));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Edit Publication</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div className="inputs-card-image">
                <img
                  src={image}
                  alt={"lab-member"}
                  style={{
                    height: "190px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "9rem", bottom: "0rem" }}
              >
                <button onClick={() => inputFileRef.current?.click()}>
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            </div>
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEdittedPublicationDetails}>
            {editPublicationInputs.map((input: any, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchEditPublication({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  onFocus={input.focus}
                  onBlur={input.blur}
                  value={input.value}
                />
              );
            })}
            <SelectFields
              selectCaption="Select authors..."
              participantsArray={authors}
              setParticipantsArray={setAuthors}
              sponsorsOrMembersArray="lab-members"
            />
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchEditPublication({
                  type: "abstract",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={editPublicationState.abstract}
            />
            {isLoading ? (
              <button className="add-button">Saving...</button>
            ) : (
              <button className="add-button">Save</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditPublication;
