import { useRef, useReducer, useState, useEffect } from "react";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import camera from "../../../assets/icons/camera.png";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import ErrorMessage from "../../../components/ErrorMessage";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import SelectFields from "../../../components/SelectFields";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { ProjectsType } from "../../Projects Page/components/Projects";
import { useEditedData } from "../../../helpers/EdittedData";
import { SponsorTypes } from "../../Home Page/components/OurPartners";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";

interface Props {
  projectDetails: ProjectsType | null;
}
function editProjectReducer(state: ProjectsType, action: any): ProjectsType {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "funding":
      return {
        ...state,
        funding: action.payload,
      };
    case "currency":
      return {
        ...state,
        currency: action.payload,
      };
    case "startDate":
      return {
        ...state,
        startDate: action.payload,
      };
    case "endDate":
      return {
        ...state,
        endDate: action.payload,
      };
    case "description":
      return {
        ...state,
        description: action.payload,
      };
    case "nonLabContributers":
      return {
        ...state,
        non_lab_contributors: action.payload
          .split(",")
          .map((contrib: string) => contrib.trim()),
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function EditProject({ projectDetails }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sponsors, setSponsors] = useState<SponsorTypes[]>(
    projectDetails?.sponsors || []
  );
  const [contributers, setContributers] = useState<LabMemberTypes[]>(
    projectDetails?.contributors || []
  );

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //project reducer
  const editProjectInitialState: ProjectsType = {
    id: projectDetails?.id || "",
    title: projectDetails?.title || "",
    funding: projectDetails?.funding || 0,
    currency: projectDetails?.currency || "",
    startDate: projectDetails?.startDate
      ? new Date(projectDetails?.startDate).toDateString()
      : "",
    endDate: projectDetails?.endDate
      ? new Date(projectDetails?.endDate).toDateString()
      : "",
    description: projectDetails?.description || "",
    non_lab_contributors: projectDetails?.non_lab_contributors || [""],
    createdAt: projectDetails?.createdAt || "",
    image: projectDetails?.image || "",
    contributors: projectDetails?.contributors || [],
    sponsors: projectDetails?.sponsors || [],
  };

  const [editProjectState, dispatchEditProject] = useReducer(
    editProjectReducer,
    editProjectInitialState
  );

  //Projects inputs array
  const editProjectInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: editProjectState.title,
    },
    {
      placeHolder: "Start date",
      type: "text",
      mainType: "startDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: editProjectState.startDate,
    },
    {
      placeHolder: "End date",
      type: "text",
      mainType: "endDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: editProjectState.endDate,
    },
    {
      placeHolder: "Funding",
      type: "text",
      mainType: "funding",
      value: editProjectState.funding,
    },
    {
      placeHolder: "Currency",
      type: "text",
      mainType: "currency",
      value: editProjectState.currency,
    },

    {
      placeHolder: "Non-Lab Contributors. Seperate with a comma ( , )",
      type: "text",
      mainType: "nonLabContributers",
      value: editProjectState.non_lab_contributors,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const inputFileRef = useRef<HTMLInputElement>(null);

  //Submit project details
  const api = useAuthorizedApi();

  const originalData = {
    title: projectDetails?.title || "",
    funding: projectDetails?.funding || 0,
    currency: projectDetails?.currency || "",
    startDate: projectDetails?.startDate,
    endDate: projectDetails?.endDate,
    description: projectDetails?.description || "",
    image: projectDetails?.image || "",
    contributors: projectDetails?.contributors.map((contributer) => ({
      id: contributer.id,
    })),
    sponsors: projectDetails?.sponsors.map((sponsor) => ({ id: sponsor.id })),
    nonLabContributors: projectDetails?.non_lab_contributors,
  };

  const newData = {
    title: editProjectState.title,
    funding: Number(editProjectState.funding),
    currency: editProjectState.currency,
    startDate: editProjectState.startDate,
    endDate: editProjectState.endDate,
    description: editProjectState.description,
    image: image,
    contributors: contributers.map((contributer) => ({ id: contributer.id })),
    sponsors: sponsors.map((sponsor) => ({ id: sponsor.id })),
    nonLabContributors: editProjectState.non_lab_contributors,
  };

  // Initialize image state with the existing sponsor image
  useEffect(() => {
    if (projectDetails?.image) {
      setImage(projectDetails.image);
    }
  }, [projectDetails, setImage]);

  const { getChangedData } = useEditedData();
  const editedData = getChangedData(originalData, newData);

  async function submitEdittedProjectDetails(
    event: React.FormEvent<HTMLFormElement>
  ) {
    const currencySymbols = ["$", "€", "£", "¥", "₹", "₵", "GHS"];

    event.preventDefault();
    if (
      validation(
        editProjectState.title.length < 2,
        "Title should be more than 2 characters"
      ) ||
      validation(!editProjectState.startDate, "Start date is required") ||
      validation(!editProjectState.endDate, "End date is required") ||
      validation(
        editProjectState.funding.toString() !== "0" &&
          !currencySymbols.includes(editProjectState.currency),
        "Please type a valid currency symbol ($, €, £, ¥, ₹, ₵, GHS)"
      ) ||
      validation(
        editProjectState.funding.toString().length < 1,
        "Funding is required. If the project is not funded, type 0"
      ) ||
      validation(
        isNaN(Number(editProjectState.funding)),
        "Funding must be a number"
      ) ||
      validation(
        contributers.length === 0 &&
          editProjectState.non_lab_contributors.length === 0,
        "A contributer is required"
      ) ||
      validation(
        editProjectState.description.length < 3,
        "Description is must be at least 3 characters"
      )
    ) {
      return;
    }

    if (Object.keys(editedData).length > 0) {
      try {
        setIsLoading(true);
        let imageUrl: string | null = originalData.image;
        if (image !== originalData.image) {
          imageUrl = await handleCloudinaryUpload(image);
          if (imageUrl) {
            editedData.image = imageUrl;
          }
        }
        await api.patch(endpoints.editProject(projectDetails?.id), editedData);
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(false));
        dispatch(setNotificationMessage("Project edited successfully"));
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage("Failed to edit project"));
        } else if (error.response.data.message) {
          dispatch(setNotificationMessage(error.response.data.message));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Edit Project</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div className="inputs-card-image">
                <img
                  src={image}
                  alt={"lab-member"}
                  style={{
                    height: "190px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "9rem", bottom: "0rem" }}
              >
                <button onClick={() => inputFileRef.current?.click()}>
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            </div>
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEdittedProjectDetails}>
            {editProjectInputs.map((input: any, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchEditProject({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  onFocus={input.focus}
                  onBlur={input.blur}
                  value={input.value}
                />
              );
            })}
            <SelectFields
              selectCaption="Select contributors..."
              participantsArray={contributers}
              setParticipantsArray={setContributers}
              sponsorsOrMembersArray="lab-members"
            />
            <SelectFields
              selectCaption="Select sponsors..."
              participantsArray={sponsors}
              setParticipantsArray={setSponsors}
              sponsorsOrMembersArray="sponsors"
            />
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchEditProject({
                  type: "description",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={editProjectState.description}
            />
            {isLoading ? (
              <button className="add-button">Saving...</button>
            ) : (
              <button className="add-button">Save</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditProject;
