import "../styles/LargeScreenContainer.scss";

interface Props {
  children: React.ReactNode;
}

/**
 * This component helps with large screen responsiveness. When you wrap your children (components) in this component, the children will be centered in large screens.
 */
function LargeScreenContainer({ children }: Props) {
  return <div className="large-screen-container-main">{children}</div>;
}

export default LargeScreenContainer;
