export const endpoints = {
  baseURL: process.env.REACT_APP_BASE_URL || "/api",
  adminRegister: "/authentication/register",
  adminLogin: "/authentication/login",
  adminForgotPassword: "/authentication/forgotpassword",
  adminResetPassword: "/authentication/resetpassword",
  addMember: "/admin/addmember",
  addEvent: "/admin/addevent",
  addSponsor: "/admin/addsponsor",
  addProject: "/admin/addproject",
  addPublication: "/admin/addpublication",
  getMembers: "/members",
  getSponsors: "/sponsors",
  getNewsAndEvents: "/events",
  getProjects: "/projects",
  getPublications: "/publications",
  messageUs: "/message-us",
  editSponsor: (sponsorId: string | undefined) =>
    `/admin/updatesponsor/${sponsorId}`,
  editProject: (projectId: string | undefined) =>
    `/admin/updateproject/${projectId}`,
  editPublication: (publicationId: string | undefined) =>
    `/admin/updatepublication/${publicationId}`,
  editLabMember: (labMemberId: string | undefined) =>
    `/admin/updatemember/${labMemberId}`,
  editNewsAndEvent: (newsAndEventId: string | undefined) =>
    `/admin/updateevent/${newsAndEventId}`,
  deleteSponsor: (sponsorId: string | undefined) =>
    `/admin/deletesponsor/${sponsorId}`,
  deleteProject: (projectId: string | undefined) =>
    `/admin/deleteproject/${projectId}`,
  deletePublication: (publicationId: string | undefined) =>
    `/admin/delete/publication/${publicationId}`,
  deleteEvent: (eventId: string | undefined) => `/admin/deleteevent/${eventId}`,
  deleteMember: (labMemberId: string | undefined) =>
    `/admin/delete/member/${labMemberId}`,
  deactivatedMember: (labMemberId: string | undefined) =>
    `/admin/deactivatemember/${labMemberId}`,
  activateMember: (labMemberId: string | undefined) =>
    `/admin/activatemember/${labMemberId}`,
};
