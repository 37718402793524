import { Link } from "react-router-dom";
import "../styles/NewsAndEvents.scss";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery } from "react-query";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";

export type NewsAndEventsType = {
  id: string;
  title: string;
  location: string;
  link: string;
  date: string;
  speakers: {
    id: string;
    fname: string;
    lname: string;
    otherNames: string;
    email: string;
    title: string;
    program: string;
    labPosition: string;
    academicPosition: string;
    image: string;
    about: string;
  }[];
  non_lab_speakers: string[];
  writeUp: string;
  images: string[];
};
function NewsAndEvents() {
  const [positionIndex, setPositionIndex] = useState<any>([0, 1, 2]);
  const [activeIndex, setActiveIndex] = useState(1);
  const rightButton = () => {
    setPositionIndex((prev: any) => {
      const updatedIndexes = prev.map((index: any) => (index + 1) % 3);
      setActiveIndex((activeIndex + 1) % 3);
      return updatedIndexes;
    });
  };

  const leftButton = () => {
    setPositionIndex((prevIndexes: any) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex: any) => (prevIndex + 2) % 3
      );
      setActiveIndex((activeIndex + 2) % 3);
      return updatedIndexes;
    });
  };

  //Handle Dot click
  const handleDotClick = (index: number) => {
    if (index !== activeIndex) {
      const newPositionIndex = positionIndex.map((_: any, idx: number) => {
        return (index - 1 + idx + 3) % 3; // Calculate new positions
      });
      setPositionIndex(newPositionIndex);
      setActiveIndex(index);
    }
  };
  const positions = ["left", "center", "right"];

  const variants = {
    left: { x: "-60%", zIndex: 2, opacity: 0 },
    center: { x: "0%", scale: 1, zIndex: 5, opacity: 1 },
    right: { x: "60%", zIndex: 1, opacity: 0 },
  };

  // Auto-slide images
  useEffect(() => {
    const intervalId = setInterval(() => {
      rightButton();
    }, 5000);

    return () => clearInterval(intervalId); // Clean up on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  //fetching news and events
  const api = useAuthorizedApi();
  const [newsAndEventsData, setNewsAndEventsData] = useState<
    NewsAndEventsType[]
  >([]);

  const { isFetching, isLoading } = useQuery(
    "newsAndEvents",
    () => {
      return api.get(endpoints.getNewsAndEvents);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        setNewsAndEventsData(response.data.events);
      },
    }
  );

  //Responsiveness
  const [containerHeight, setContainerHeight] = useState("auto");
  const updateContainerHeight = () => {
    const screenWidth = window.innerWidth;
    let newHeight;
    const baseHeight = 22; // Base height in rem for the largest screen size
    const heightIncrement = 1.8; // Increment in rem per breakpoint

    if (screenWidth <= 320) {
      newHeight = 15 + ((1800 - screenWidth) / 100) * 2.8;
    } else if (screenWidth <= 400) {
      newHeight = 9 + ((1800 - screenWidth) / 100) * 3;
    } else if (screenWidth <= 500) {
      newHeight = 5 + ((1800 - screenWidth) / 100) * 3;
    } else if (screenWidth <= 1400) {
      newHeight = 28 + ((1800 - screenWidth) / 100) * 2.2;
    } else if (screenWidth <= 1500) {
      newHeight = baseHeight + ((1800 - screenWidth) / 100) * heightIncrement;
    } else {
      newHeight = 27; // Default height for larger screens
    }

    setContainerHeight(`${newHeight}rem`);
  };

  useEffect(() => {
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);

  return (
    <div className="home-news-and-events-main-container">
      <div className="home-news-and-events-header">
        <h2>News & Events</h2>
      </div>
      <div
        className="home-news-and-events-container"
        style={{
          position: "relative",
          height: isLoading ? "100%" : containerHeight,
          maxHeight: isLoading ? "40rem" : containerHeight,
        }}
      >
        {isFetching ? (
          <>
            <motion.div className="skeleton-loading-container">
              <div className="skeleton-header"></div>
              <div className="skeleton-details-date">
                <span></span>
                <span></span>
              </div>
              <div className="skeleton-details">
                <div className="skeleton-dummy-image"></div>
                <div className="skeleton-dummy-description">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </motion.div>
          </>
        ) : (
          <>
            {newsAndEventsData && newsAndEventsData.length > 0 ? (
              <>
                <AnimatePresence initial={false}>
                  {newsAndEventsData.slice(0, 3).map((events, index) => {
                    let shortenedDescription;
                    if (window.innerWidth < 500) {
                      shortenedDescription = events.writeUp
                        .trim()
                        .substring(0, 250);
                    } else {
                      shortenedDescription = events.writeUp
                        .trim()
                        .substring(0, 350);
                    }

                    //Convert date to normal format
                    const date = new Date(events.date);
                    const formattedDate = date.toLocaleDateString("default", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    });
                    return (
                      <motion.div
                        className="home-events-container"
                        key={index}
                        style={{
                          position: "absolute",
                          width: "70%",
                          margin: "auto",
                        }}
                        initial="center"
                        animate={positions[positionIndex[index]]}
                        variants={variants}
                        transition={{ duration: 0.8 }}
                      >
                        <div className="home-event-title">
                          <h3>{events.title}</h3>
                        </div>
                        <div className="home-event-minor-details">
                          <p>Event</p>
                          <p>{`${formattedDate}`}</p>
                        </div>
                        <div className="home-event-main-details">
                          <div className="home-event-image">
                            <img
                              src={events.images[0]}
                              loading="lazy"
                              alt="news and events"
                            />
                          </div>
                          <p
                            className="home-event-description"
                            style={{ width: "100%" }}
                          >
                            {shortenedDescription + "..."}
                            <span>
                              <Link to={`/events/details/${events.id}`}>
                                Read More
                              </Link>
                            </span>
                          </p>
                        </div>
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </>
            ) : (
              <>
                <div className="empty-message">
                  <p>No current news and events</p>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {newsAndEventsData && newsAndEventsData.length > 0 ? (
        <>
          <div className="event-details-image-slider-controls">
            <span className="left" onClick={leftButton}>
              {"<"}
            </span>
            {newsAndEventsData.slice(0, 3).map((_, index) => {
              return (
                <span
                  className={`dot ${index === activeIndex ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                  key={index}
                />
              );
            })}
            <span className="right" onClick={rightButton}>
              {">"}
            </span>
          </div>
        </>
      ) : (
        <>{""}</>
      )}
    </div>
  );
}

export default NewsAndEvents;
