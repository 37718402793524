import Navigation from "../../../components/Navigation";
import Footer from "../../../components/Footer";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import Publications from "./Publications";
import { RootState } from "../../../types/GlobalTypes";
import { useSelector } from "react-redux";
import AdminNavigation from "../../Admin/components/AdminNavigation";

function PublicationsMain() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  return (
    <>
      <Navigation />
      {isLoggedIn && <AdminNavigation />}
      <LargeScreenContainer>
        <Publications />
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default PublicationsMain;
