export function useEditedData<T extends Record<string, any>>() {
 function isEqual(a: any, b: any): boolean {
   // Handle strict equality
   if (a === b) return true;

   // Handle array comparison
   if (Array.isArray(a) && Array.isArray(b)) {
     if (a.length !== b.length) return false;
     return a.every((item, index) => isEqual(item, b[index]));
   }

   // Handle date comparison
   if (a instanceof Date && b instanceof Date) {
     return a.getTime() === b.getTime();
   }

   // Handle object comparison
   if (
     typeof a === "object" &&
     a !== null &&
     typeof b === "object" &&
     b !== null
   ) {
     const aKeys = Object.keys(a);
     const bKeys = Object.keys(b);

     // Ensure both objects have the same number of keys
     if (aKeys.length !== bKeys.length) return false;

     // Compare each key-value pair recursively
     return aKeys.every(
       (key) => b.hasOwnProperty(key) && isEqual(a[key], b[key])
     );
   }

   // If none of the above conditions match, they're not equal
   return false;
 }


  function getChangedData(originalData: T, newData: Partial<T>): Partial<T> {
    const changedData: Partial<T> = {};
    (Object.keys(newData) as Array<keyof T>).forEach((key) => {
      if (
        newData[key] !== undefined && // Ensure key exists in newData
        originalData.hasOwnProperty(key) && // Ensure originalData has this key
        !isEqual(newData[key as keyof T], originalData[key]) // Compare the two
      ) {
        changedData[key] = newData[key]!;
      }
    });
    return changedData;
  }

  return {
    getChangedData,
  };
}
