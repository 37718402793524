import "../styles/LabMembers.scss";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import Modal from "../../../components/Modal";
import EditMember from "../../Admin/components/EditMember";
import Delete from "../../Admin/components/Delete";
import { useQuery } from "react-query";
import { authenticationApi, useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import Notification from "../../../components/Notification";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { AnimatePresence, motion } from "framer-motion";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";
import { NewsAndEventsType } from "../../Home Page/components/NewsAndEvents";
import { ProjectsType } from "../../Projects Page/components/Projects";
import { PublicationType } from "../../Publication Page/components/Publications";

export type LabMemberTypes = {
  about: string;
  academicPosition: string;
  email: string;
  fname: string;
  id?: string;
  image?: string;
  labPosition: string;
  lname: string;
  otherNames: "";
  program: string;
  title: string;
  event?: NewsAndEventsType[];
  isActive?: boolean;
  projects?: ProjectsType[];
  publications?: PublicationType[];
};
function LabMembers() {
  const dispatch = useDispatch();
  const [labMembersState, setLabMembersState] = useState<{
    main: LabMemberTypes[];
    details: LabMemberTypes | null;
  }>({ main: [], details: null });

  //Open modal states
  const [editLabMember, setEditLabMember] = useState<boolean>(false);
  const [deleteMember, setDeleteMember] = useState<boolean>(false);
  const [deactivateMember, setDeactivateMember] = useState<boolean>(false);
  const [activateMember, setActivateMember] = useState<boolean>(false);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //loading deactivate and delete animations
  const [deleteMemberLoading, setDeleteMemberLoading] =
    useState<boolean>(false);

  //Fecthing lab members
  const api = useAuthorizedApi();
  const { refetch, isFetching } = useQuery(
    "lab-members",
    () => {
      return authenticationApi.get(endpoints.getMembers);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setLabMembersState({ ...labMembersState, main: response.data.members });
      },
    }
  );

  async function deleteLabMember(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    try {
      setDeleteMemberLoading(true);
      await api.delete(endpoints.deleteMember(labMembersState.details?.id));
      setDeleteMemberLoading(false);
      setDeleteMember(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname} deleted successfully`
        )
      );
      refetch();
    } catch (error: any) {
      setDeleteMemberLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to delete project"));
      }
    }
  }
  async function deactivateLabMember(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    try {
      setDeleteMemberLoading(true);
      await api.patch(endpoints.deactivatedMember(labMembersState.details?.id));
      setDeleteMemberLoading(false);
      setDeactivateMember(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname} deactivated`
        )
      );
      refetch();
    } catch (error: any) {
      setDeleteMemberLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to delete lab member"));
      }
    }
  }

  async function activateLabMember(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    try {
      setDeleteMemberLoading(true);
      await api.patch(endpoints.activateMember(labMembersState.details?.id));
      setDeleteMemberLoading(false);
      setActivateMember(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname} activated`
        )
      );
      refetch();
    } catch (error: any) {
      setDeleteMemberLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to activate lab member"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);
  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      {editLabMember && (
        <Modal onClose={() => setEditLabMember(false)}>
          <EditMember labMemberDetails={labMembersState.details} />
        </Modal>
      )}
      {deleteMember && (
        <Modal onClose={() => setDeleteMember(false)}>
          <Delete
            name={`${labMembersState.details?.title} ${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname}`}
            type="delete"
            deleteFunction={deleteLabMember}
            close={() => setDeleteMember(false)}
            isLoading={deleteMemberLoading}
          />
        </Modal>
      )}
      {deactivateMember && (
        <Modal onClose={() => setDeactivateMember(false)}>
          <Delete
            name={`${labMembersState.details?.title} ${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname}`}
            type="deactivate"
            deleteFunction={deactivateLabMember}
            close={() => setDeactivateMember(false)}
            isLoading={deleteMemberLoading}
          />
        </Modal>
      )}
      {activateMember && (
        <Modal onClose={() => setActivateMember(false)}>
          <Delete
            name={`${labMembersState.details?.title} ${labMembersState.details?.fname} ${labMembersState.details?.otherNames} ${labMembersState.details?.lname}`}
            type="activate"
            deleteFunction={activateLabMember}
            close={() => setActivateMember(false)}
            isLoading={deleteMemberLoading}
          />
        </Modal>
      )}
      <div className="lab-members-main-container">
        <div className="lab-members-header">
          <h1>Lab Members</h1>
        </div>
        <div className="lab-members-container">
          {isFetching ? (
            <>
              {[0, 1, 2, 3, 4].map((_, index) => {
                return (
                  <div className="skeletal-loading-main-container" key={index}>
                    <div className="skeletal-dummy-image"></div>
                    <div className="skeletal-dummy-name-and-position">
                      <span></span>
                      <span></span>
                    </div>
                    <div className="skeletal-dummy-description">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {labMembersState.main && labMembersState.main.length > 0 ? (
                <>
                  {labMembersState?.main
                    .filter((labmember) => {
                      if (isLoggedIn) {
                        return true;
                      } else {
                        return labmember.isActive;
                      }
                    })
                    .map((labmember, index) => {
                      const shortenedDescription = labmember.about
                        .split(" ")
                        .slice(0, 25)
                        .join(" ");

                      return (
                        <AnimatePresence key={index}>
                          <motion.div
                            className="lab-member-main"
                            key={index}
                            onClick={() =>
                              setLabMembersState({
                                ...labMembersState,
                                details: labmember,
                              })
                            }
                          >
                            <div className="lab-member-profile">
                              <img
                                src={labmember.image}
                                alt={labmember.fname}
                                loading="lazy"
                              />
                            </div>
                            <Link to={`/labmembers/details/${labmember.id}`}>
                              <p className="lab-member-positions">
                                <span>{`${capitalizeFirstLetter(
                                  labmember.title
                                )} ${capitalizeFirstLetter(
                                  labmember.fname
                                )} ${capitalizeFirstLetter(
                                  labmember.otherNames
                                )} ${capitalizeFirstLetter(
                                  labmember.lname
                                )}`}</span>
                                <span>
                                  {capitalizeFirstLetter(labmember.labPosition)}
                                </span>
                              </p>
                            </Link>
                            <p className="lab-member-about">
                              {capitalizeFirstLetter(shortenedDescription) +
                                "..."}
                            </p>
                            {isLoggedIn && (
                              <div
                                className="lab-member-admin-buttons"
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <button onClick={() => setEditLabMember(true)}>
                                  Edit
                                </button>
                                <button onClick={() => setDeleteMember(true)}>
                                  Delete
                                </button>
                                {labmember.isActive ? (
                                  <>
                                    <button
                                      onClick={() => setDeactivateMember(true)}
                                    >
                                      Deactivate
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={() => setActivateMember(true)}
                                    >
                                      Activate
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                          </motion.div>
                        </AnimatePresence>
                      );
                    })}
                </>
              ) : (
                <>
                  <div className="empty-message">
                    <p>No lab members available</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LabMembers;
