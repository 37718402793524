import { useReducer } from "react";
import { useRef, useState, useEffect } from "react";
import camera from "../../../assets/icons/camera.png";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import ErrorMessage from "../../../components/ErrorMessage";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import testImage from "../../../assets/images/default-image.jpg";
import { endpoints } from "../../../services/Endpoints";
import { useAuthorizedApi } from "../../../services/Axios";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { useDispatch, useSelector } from "react-redux";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { RootState } from "../../../types/GlobalTypes";

function labMemberReducer(state: LabMemberTypes, action: any): LabMemberTypes {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "firstname":
      return {
        ...state,
        fname: action.payload,
      };
    case "lastname":
      return {
        ...state,
        lname: action.payload,
      };
    case "otherName":
      return {
        ...state,
        otherNames: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "program":
      return {
        ...state,
        program: action.payload,
      };
    case "labPosition":
      return {
        ...state,
        labPosition: action.payload,
      };
    case "academicPosition":
      return {
        ...state,
        academicPosition: action.payload,
      };
    case "about":
      return {
        ...state,
        about: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function AddMember() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const labMemberInitialState: LabMemberTypes = {
    title: "",
    fname: "",
    lname: "",
    otherNames: "",
    email: "",
    program: "",
    labPosition: "",
    academicPosition: "",
    about: "",
  };

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  const [labMemberState, dispatchLabMember] = useReducer(
    labMemberReducer,
    labMemberInitialState
  );

  const addMemberInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: labMemberState.title,
    },
    {
      placeHolder: "First name",
      type: "text",
      mainType: "firstname",
      value: labMemberState.fname,
    },
    {
      placeHolder: "Last name",
      type: "text",
      mainType: "lastname",
      value: labMemberState.lname,
    },
    {
      placeHolder: "Other names",
      type: "text",
      mainType: "otherName",
      value: labMemberState.otherNames,
    },
    {
      placeHolder: "Email",
      type: "text",
      mainType: "email",
      value: labMemberState.email,
    },
    {
      placeHolder: "Program",
      type: "text",
      mainType: "program",
      value: labMemberState.program,
    },
    {
      placeHolder: "Lab position",
      type: "text",
      mainType: "labPosition",
      value: labMemberState.labPosition,
    },
    {
      placeHolder: "Academic position",
      type: "text",
      mainType: "academicPosition",
      value: labMemberState.academicPosition,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  //validation function
  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();

  const api = useAuthorizedApi();
  async function submitMemberDetails(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(image === testImage, "Please upload an image") ||
      validation(
        labMemberState.title.length < 2,
        "Please type a valid title (Mr., Mrs., Prof., Miss., etc.)"
      ) ||
      validation(
        labMemberState.fname.length < 2,
        "Firstname should be more than 2 characters"
      ) ||
      validation(
        labMemberState.lname.length < 2,
        "Lastname should be more than 2 characters"
      ) ||
      validation(
        !labMemberState.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        "Please type a valid email"
      ) ||
      validation(
        labMemberState.program.length < 3,
        "Program should be more than 3 characters"
      ) ||
      validation(
        labMemberState.labPosition.length < 3,
        "Lab position should be more than 3 characters"
      ) ||
      validation(
        labMemberState.academicPosition.length < 3,
        "Academic position should be more than 3 characters"
      ) ||
      validation(
        labMemberState.about.length < 3,
        "About should be more than 3 characters"
      )
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const uploadedUrl = await handleCloudinaryUpload(image);

      const data = {
        fname: labMemberState.fname,
        lname: labMemberState.lname,
        otherNames: labMemberState.otherNames,
        email: labMemberState.email,
        title: labMemberState.title,
        program: labMemberState.program,
        labPosition: labMemberState.labPosition,
        academicPosition: labMemberState.academicPosition,
        image: uploadedUrl,
        about: labMemberState.about,
      };

      await api.post(endpoints.addMember, data);
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(setNotificationMessage("Lab member added successfully"));
      setImage(testImage);
      dispatchLabMember({ type: "reset", payload: labMemberInitialState });
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage("Failed to add lab member"));
      } else if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  const inputFileRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Add Member</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div
                className="inputs-card-image"
                style={{ position: "relative", border: "none" }}
              >
                <img
                  src={image}
                  alt={"lab-member"}
                  style={{
                    height: "190px",
                    width: "200px",
                    objectFit: "cover",
                    borderRadius: "100px",
                    border: "1px solid black",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "14rem", bottom: "2rem" }}
              >
                <button
                  onClick={() => {
                    inputFileRef.current?.click();
                    setShowErrorMessage(false);
                  }}
                >
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/jpg"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={handleImageChange}
              />
            </div>
            {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          </div>
          <form onSubmit={submitMemberDetails}>
            {addMemberInputs.map((input, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchLabMember({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  value={input.value}
                />
              );
            })}
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchLabMember({
                  type: "about",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={labMemberState.about}
            />
            {isLoading ? (
              <button className="add-button">Adding...</button>
            ) : (
              <button className="add-button">Add Lab Member</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddMember;
