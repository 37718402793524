import { createSlice } from "@reduxjs/toolkit";

export type AdminNotificationReducer = {
  showNotification: boolean;
  errorNotification: boolean;
  notificationMessage: string;
};

const initialState: AdminNotificationReducer = {
  showNotification: false,
  errorNotification: false,
  notificationMessage: "",
};

const adminNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setShowNotification: (state, action) => {
      state.showNotification = action.payload;
    },
    setErrorNotification: (state, action) => {
      state.errorNotification = action.payload;
    },
    setNotificationMessage: (state, action) => {
      state.notificationMessage = action.payload;
    },
  },
});

export const {
  setShowNotification,
  setNotificationMessage,
  setErrorNotification,
} = adminNotificationSlice.actions;
export default adminNotificationSlice.reducer;
