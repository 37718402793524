import { useReducer, useState } from "react";
import "../styles/ContactUs.scss";
import { endpoints } from "../../../services/Endpoints";
import { authenticationApi } from "../../../services/Axios";
import { motion } from "framer-motion";
import messageClose from "../../../assets/icons/mesage-close.png";

type ContactUsTypes = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
};

function contactUsReducer(state: ContactUsTypes, action: any): ContactUsTypes {
  switch (action.type) {
    case "firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "subject":
      return {
        ...state,
        subject: action.payload,
      };
    case "message":
      return {
        ...state,
        message: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function ContactUs() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNotificationMessage, setShowNotificationMessage] =
    useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [errorNotification, setErrorNotification] = useState<boolean>(false);

  const contactUsInitialState: ContactUsTypes = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  };
  const [contactUsState, dispatchContactUs] = useReducer(
    contactUsReducer,
    contactUsInitialState
  );

  //disable button
  function disableButton() {
    if (
      contactUsState.firstName === "" ||
      contactUsState.lastName === "" ||
      !contactUsState.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ||
      contactUsState.subject === "" ||
      contactUsState.message === ""
    ) {
      return true;
    } else return false;
  }

  //Submit contact us page
  async function submitMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      setIsLoading(true);
      const data = {
        firstName: contactUsState.firstName,
        lastName: contactUsState.lastName,
        email: contactUsState.email,
        subject: contactUsState.subject,
        message: contactUsState.message,
      };
      await authenticationApi.post(endpoints.messageUs, data);
      setErrorNotification(false);
      setIsLoading(false);
      setShowNotificationMessage(true);
      setNotificationMessage(
        "We've received your message and will get back to you shortly."
      );
      dispatchContactUs({ type: "reset", payload: contactUsInitialState });
    } catch (error: any) {
      if (error.response) {
        setErrorNotification(true);
        setIsLoading(false);
        setShowNotificationMessage(true);
        setNotificationMessage(
          "Failed to send message. Please try again later."
        );
      }
    }
  }

  return (
    <>
      <article className="contact-us-main-container">
        <section className="contact-us-header">
          <h1>Get in Touch</h1>
          <p>
            We'd love to hear from you! Fill out the form below, and we'll get
            back to you as soon as possible. Let's connect and explore
            possibilities together!
          </p>
        </section>
        <section className="contact-us-location">
          <div className="contact-us-location-description">
            <div className="contact-us-location-header">
              <p>Where to find us</p>
            </div>
            <div className="contact-us-address">
              <p>Address</p>
              <p>
                Faculty of Electrical and Computer Engineering, KNUST - Kumasi
              </p>
            </div>
            <div className="contact-us-email">
              <p>Email</p>
              <p>dipperlab@knust.edu.gh</p>
            </div>
            <div className="contact-us-telephone">
              <p>Telephone</p>
              <p>(+233)-32-249-3435</p>
            </div>
          </div>
          <div className="contact-us-google-map-location"></div>
        </section>
        <section className="contact-us-form">
          <div className="text-inputs-header-and-message">
            <p className="send-message-container">Send us a message</p>
            {showNotificationMessage && (
              <p
                className="notification-message"
                style={{
                  backgroundColor: errorNotification ? "#ff8080" : "#b0f0b0",
                  color: errorNotification ? "#400000" : "#005000",
                }}
              >
                {notificationMessage}
                <img
                  src={messageClose}
                  alt="close"
                  onClick={() => setShowNotificationMessage(false)}
                  style={{
                    cursor: "pointer",
                    filter: errorNotification
                      ? "invert(8%) sepia(27%) saturate(7495%) hue-rotate(349deg) brightness(91%) contrast(113%)"
                      : "invert(16%) sepia(70%) saturate(2505%) hue-rotate(101deg) brightness(93%) contrast(105%)",
                  }}
                />
              </p>
            )}
          </div>
          <form onSubmit={submitMessage}>
            <div className="name-inputs">
              <input
                type="text"
                required
                placeholder="Firstname"
                value={contactUsState.firstName}
                onChange={(e) =>
                  dispatchContactUs({
                    type: "firstName",
                    payload: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Lastname"
                value={contactUsState.lastName}
                onChange={(e) =>
                  dispatchContactUs({
                    type: "lastName",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className="message-info-inputs">
              <input
                type="email"
                required
                placeholder="Email"
                value={contactUsState.email}
                onChange={(e) =>
                  dispatchContactUs({ type: "email", payload: e.target.value })
                }
              />
              <input
                type="text"
                required
                placeholder="Message Subject"
                value={contactUsState.subject}
                onChange={(e) =>
                  dispatchContactUs({
                    type: "subject",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <textarea
              required
              placeholder="Message"
              value={contactUsState.message}
              onChange={(e) =>
                dispatchContactUs({ type: "message", payload: e.target.value })
              }
            />
            <br />
            <div
              className="contact-us-submit-buttons"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                marginTop: "1rem",
              }}
            >
              <button
                disabled={disableButton()}
                style={{
                  backgroundColor: disableButton() === true ? "gray" : "",
                  cursor: disableButton() === true ? "not-allowed" : "pointer",
                }}
              >
                Send
              </button>
              {isLoading ? (
                <motion.div
                  style={{
                    width: "28px",
                    height: "28px",
                    border: "5px solid #ccc",
                    borderTop: "5px solid #d90429",
                    borderRadius: "50%",
                  }}
                  animate={{ rotate: 360 }}
                  transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                ></motion.div>
              ) : (
                " "
              )}
            </div>
          </form>
        </section>
      </article>
    </>
  );
}

export default ContactUs;
