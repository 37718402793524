import { useState, useEffect } from "react";

export default function useResponsiveFunction() {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 1200
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isSmallScreen };
}
