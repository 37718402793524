import { createSlice } from "@reduxjs/toolkit";

export type AdminAboutReducerState = {
  openAddAbout: boolean;
  openEditAbout: boolean;
};

const initialState: AdminAboutReducerState = {
  openAddAbout: false,
  openEditAbout: false,
};

const adminButtonsSlice = createSlice({
  name: "admin-about-buttons",
  initialState,
  reducers: {
    setOpenAddAbout: (state, action) => {
      state.openAddAbout = action.payload;
    },
    setOpenEditAbout: (state, action) => {
      state.openEditAbout = action.payload;
    },
  },
});

export const { setOpenAddAbout, setOpenEditAbout } = adminButtonsSlice.actions;
export default adminButtonsSlice.reducer;
