import React from "react";

interface Prop {
  name: string | undefined;
  deleteFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  close: () => void;
  isLoading: boolean;
  type: "delete" | "deactivate" | "activate";
}
function Delete({ name, deleteFunction, close, isLoading, type }: Prop) {
  return (
    <>
      <div className="delete-main-container">
        <span>{`Are you sure you want to ${type} ${name}`}</span>
        <div className="buttons">
          <button onClick={deleteFunction}>
            {isLoading ? `Deleting...` : `Yes`}
          </button>
          <button onClick={close}>No</button>
        </div>
      </div>
    </>
  );
}

export default Delete;
