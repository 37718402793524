import { useDispatch, useSelector } from "react-redux";
import "../styles/Projects.scss";
import { Link } from "react-router-dom";
import { RootState } from "../../../types/GlobalTypes";
import { useState, useEffect } from "react";
import EditProject from "../../Admin/components/EditProject";
import Modal from "../../../components/Modal";
import Delete from "../../Admin/components/Delete";
import { useQuery } from "react-query";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { SponsorTypes } from "../../Home Page/components/OurPartners";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";

export type ProjectsType = {
  id: string;
  title: string;
  createdAt: string;
  currency: string;
  description: string;
  startDate: Date | string;
  endDate: Date | string;
  funding: number;
  image: string;
  contributors: LabMemberTypes[];
  sponsors: SponsorTypes[];
  non_lab_contributors: string[] | string;
};
function Projects() {
  const dispatch = useDispatch();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const [editProject, setEditProject] = useState(false);
  const [openDeleteProject, setOpenDeleteProject] = useState(false);

  //projects states
  const [projectState, setProjectState] = useState<{
    main: ProjectsType[];
    details: ProjectsType | null;
  }>({
    main: [],
    details: null,
  });

  //Fetching projects
  const api = useAuthorizedApi();
  const { isFetching, refetch } = useQuery(
    "projects",
    () => {
      return api.get(endpoints.getProjects);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setProjectState({ ...projectState, main: response.data.projects });
      },
    }
  );

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  async function submitDeletedProject(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();

    try {
      setIsDeleteLoading(true);
      await api.delete(endpoints.deleteProject(projectState.details?.id));
      setIsDeleteLoading(false);
      setOpenDeleteProject(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${projectState.details?.title} deleted successfully`
        )
      );
      refetch();
    } catch (error: any) {
      setIsDeleteLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to delete project"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      {editProject && (
        <Modal onClose={() => setEditProject(false)}>
          <EditProject projectDetails={projectState.details} />
        </Modal>
      )}
      {openDeleteProject && (
        <Modal onClose={() => setOpenDeleteProject(false)}>
          <Delete
            name={projectState.details?.title}
            close={() => setOpenDeleteProject(false)}
            deleteFunction={submitDeletedProject}
            isLoading={isDeleteLoading}
            type="delete"
          />
        </Modal>
      )}
      <div className="projects-main-container">
        <div className="projects-header">
          <h1>Projects</h1>
        </div>
        <div className="projects-list-container">
          {isFetching ? (
            <>
              {[0, 1, 2, 3, 4].map((_, index) => {
                return (
                  <div className="skeletal-loading-main-container" key={index}>
                    <div className="skeletal-dummy-image"></div>
                    <div className="skeletal-dummy-title"></div>
                    <div className="skeletal-dummy-description">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {projectState.main && projectState.main.length > 0 ? (
                <>
                  {projectState.main.map((project) => {
                    const shortenedDescription = project.description
                      .trim()
                      .substring(0, 250);
                    return (
                      <div
                        className="project-main"
                        key={project.id}
                        onClick={() =>
                          setProjectState({ ...projectState, details: project })
                        }
                      >
                        <div className="project-image">
                          <img src={project.image} alt={project.title} />
                        </div>
                        <Link to={`/projects/details/${project.id}`}>
                          <div className="project-title">
                            <h4>
                              {capitalizeFirstLetter(
                                project?.title.trim().substring(0, 50)
                              ) + "..."}
                            </h4>
                          </div>
                        </Link>
                        <p className="project-description">
                          {shortenedDescription + "..."}
                        </p>
                        {isLoggedIn && (
                          <div
                            className="project-admin-buttons"
                            style={{
                              display: isLoggedIn ? "flex" : "block",
                              gap: "1rem",
                            }}
                          >
                            <button onClick={() => setEditProject(true)}>
                              Edit
                            </button>

                            <button onClick={() => setOpenDeleteProject(true)}>
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="empty-message">
                    <p>No projects available</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Projects;
