import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../../store/reducers/AuthReducer";
import "../styles/AdminComponentStyles.scss";

interface Props {
  close: () => void;
}
function Logout({ close }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="logout-modal">
        <span>Are you sure you want to logout?</span>
        <div className="logout-modal-buttons">
          <button
            onClick={() => {
              dispatch(setToken(null));
              localStorage.clear();
              window.location.reload();
              navigate("/");
              close();
            }}
          >
            Logout
          </button>
          <button onClick={() => close()}>Cancel</button>
        </div>
      </div>
    </>
  );
}

export default Logout;
