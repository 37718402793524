import { useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import Navigation from "../../../components/Navigation";
import Projects from "./Projects";
import { RootState } from "../../../types/GlobalTypes";
import AdminNavigation from "../../Admin/components/AdminNavigation";

function ProjectsMain() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  return (
    <>
      <Navigation />
      {isLoggedIn && <AdminNavigation />}
      <LargeScreenContainer>
        <Projects />
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default ProjectsMain;
