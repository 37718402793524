import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import ProjectReducer from "./reducers/ProjectReducer";
import AdminButtonsReducer from "./reducers/AdminButtonsReducer";
import AdminAboutReducer from "./reducers/AdminAboutReducer";
import NotificationReducer from "./reducers/AdminNotificationReducer";
import AuthReducer from "./reducers/AuthReducer";

const reducers = combineReducers({
  project: ProjectReducer,
  adminButtons: AdminButtonsReducer,
  adminAboutButtons: AdminAboutReducer,
  adminNotifications: NotificationReducer,
  authentication: AuthReducer,
});

const store = configureStore({
  reducer: reducers,
});

export default store;
