import "../styles/Footer.scss";
import dipperLabLogo from "../assets/images/dipper-lab-logo.png";
import twitter from "../assets/icons/x.png";
import email from "../assets/icons/email.svg";
import phone from "../assets/icons/phone.svg";
import linkedIn from "../assets/icons/linkedin.svg";
import instagram from "../assets/icons/instagram.svg";
import { Link } from "react-router-dom";

/**
 * A React Component that renders the footer of the website.
 */
function Footer() {
  const socialMedia = [
    {
      icon: twitter,
      link: "https://twitter.com/dipperlabknust",
    },
    {
      icon: instagram,
      link: "https://www.instagram.com/dipperlab/",
    },
    {
      icon: linkedIn,
      link: "https://www.linkedin.com/company/distributed-iot-platforms-privacy-and-edge-intelligence-research-dipper-lab/mycompany/",
    },
    {
      icon: email,
      link: "mailto:dipperlab@knust.edu.gh",
    },
    {
      icon: phone,
      link: "tel:+233322493435",
    },
  ];
  return (
    <footer className="footer-main-comtainer">
      <div className="footer-logo">
        <Link to={"/"}>
          <img src={dipperLabLogo} alt="dipper lab logo" />
        </Link>
      </div>
      <p className="footer-description">
        © Distributed IoT Platform, Privacy and Edge-Intelligence Research Lab
      </p>
      <div className="footer-social-media">
        {socialMedia.map((socialMedia, index) => {
          return (
            <Link to={socialMedia.link} target="_blank" key={index}>
              <img src={socialMedia.icon} alt="dipper-lab-icons" />
            </Link>
          );
        })}
      </div>
    </footer>
  );
}

export default Footer;
