import { useDispatch, useSelector } from "react-redux";
import {
  setOpenAddMember,
  setOpenAddPublication,
  setOpenAddEvent,
  setOpenAddProject,
  setOpenAddSponsor,
} from "../../../store/reducers/AdminButtonsReducer";
import "../styles/AdminNavigation.scss";
import { RootState } from "../../../types/GlobalTypes";
import Modal from "../../../components/Modal";
import AddProject from "./AddProject";
import AddPublication from "./AddPublication";
import AddMember from "./AddMember";
import AddEvent from "./AddEvent";
import AddSponsor from "./AddSponsor";
import logoutIcon from "../../../assets/icons/icons8-logout-100.png";
import { useState } from "react";
import Logout from "./Logout";

function AdminNavigation() {
  const dispatch = useDispatch();
  const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false);

  // Array of objects representing the admin navigation buttons
  const adminNavigationButtons = [
    {
      action: "Add Project",
      open: () => dispatch(setOpenAddProject(true)),
    },
    {
      action: "Add Publications",
      open: () => dispatch(setOpenAddPublication(true)),
    },
    {
      action: "Add Members",
      open: () => dispatch(setOpenAddMember(true)),
    },
    {
      action: "Add Event",
      open: () => dispatch(setOpenAddEvent(true)),
    },
    {
      action: "Add Sponsor",
      open: () => dispatch(setOpenAddSponsor(true)),
    },
  ];

  // Retrieves the state of opening the add project modal
  const openAddProject = useSelector(
    (state: RootState) => state.adminButtons.openAddProject
  );
  const openAddPublication = useSelector(
    (state: RootState) => state.adminButtons.openAddPublication
  );
  const openAddMember = useSelector(
    (state: RootState) => state.adminButtons.openAddMember
  );
  const openAddEvent = useSelector(
    (state: RootState) => state.adminButtons.openAddEvent
  );
  const openAddSponsor = useSelector(
    (state: RootState) => state.adminButtons.openAddSponsor
  );

  return (
    <>
      {openAddProject && (
        <Modal onClose={() => dispatch(setOpenAddProject(false))}>
          <AddProject />
        </Modal>
      )}
      {openAddPublication && (
        <Modal onClose={() => dispatch(setOpenAddPublication(false))}>
          <AddPublication />
        </Modal>
      )}
      {openAddMember && (
        <Modal onClose={() => dispatch(setOpenAddMember(false))}>
          <AddMember />
        </Modal>
      )}
      {openAddEvent && (
        <Modal onClose={() => dispatch(setOpenAddEvent(false))}>
          <AddEvent />
        </Modal>
      )}
      {openAddSponsor && (
        <Modal onClose={() => dispatch(setOpenAddSponsor(false))}>
          <AddSponsor />
        </Modal>
      )}

      {openLogoutModal && (
        <Modal onClose={() => setOpenLogoutModal(false)}>
          <Logout close={() => setOpenLogoutModal(false)} />
        </Modal>
      )}
      <div className="admin-navigation-main-container">
        <div className="admin-buttons-container">
          {adminNavigationButtons.map((button, index) => {
            return (
              <button onClick={() => button.open()} key={index}>
                {button.action}
              </button>
            );
          })}
        </div>
        <div className="logout-container">
          <button onClick={() => setOpenLogoutModal(true)}>
            <p>Logout</p>
            <img
              src={logoutIcon}
              alt="shitdown"
              style={{ cursor: "pointer" }}
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminNavigation;
