import testImage from "../../../assets/images/default-image.jpg";
import { useReducer, useRef, useState, useEffect } from "react";
import camera from "../../../assets/icons/camera.png";
import axios from "axios";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import Notification from "../../../components/Notification";
import ErrorMessage from "../../../components/ErrorMessage";
import "../styles/AdminComponentStyles.scss";
import SelectFields from "../../../components/SelectFields";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import {
  setErrorNotification,
  setNotificationMessage,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";

type EventType = {
  title: string;
  about: string;
  time: string;
  date: string;
  location: string;
  videoLink: string;
  speaker: string[];
  nonLabMembers: string;
};

function eventReducer(state: EventType, action: any) {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "about":
      return {
        ...state,
        about: action.payload,
      };
    case "time":
      return {
        ...state,
        time: action.payload,
      };
    case "date":
      return {
        ...state,
        date: action.payload,
      };
    case "location":
      return {
        ...state,
        location: action.payload,
      };
    case "videoLink":
      return {
        ...state,
        videoLink: action.payload,
      };
    case "speaker":
      return {
        ...state,
        speaker: action.payload,
      };
    case "nonLabMembers":
      return {
        ...state,
        nonLabMembers: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function AddEvent() {
  const [eventImage, setEventImage] = useState<{
    1: string;
    2: string;
    3: string;
  }>({ 1: testImage, 2: testImage, 3: testImage });

  const [imageUrl, setImageUrl] = useState<{
    1: string;
    2: string;
    3: string;
  }>({ 1: "", 2: "", 3: "" });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  //dispatch function
  const dispatch = useDispatch();

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const [eventNumber, setEventNumber] = useState<number>(0);
  const eventImages = [
    { image: eventImage[1], alt: "event-image-1" },
    { image: eventImage[2], alt: "event-image-2" },
    { image: eventImage[3], alt: "event-image-3" },
  ];

  const eventInitialState: EventType = {
    title: "",
    about: "",
    time: "",
    date: "",
    location: "",
    videoLink: "",
    speaker: [],
    nonLabMembers: "",
  };

  const [eventState, dispatchEvent] = useReducer(
    eventReducer,
    eventInitialState
  );
  //See event image
  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the uploaded image's base64 to state
        setEventImage({
          ...eventImage,
          [eventNumber + 1]: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  //Handle multiple image upload to cloudinary
  const { cloudName, presetkey } = useCloudinaryUpload();
  async function handleCloudinaryUpload() {
    const updatedImageUrls = { ...imageUrl };

    for (let i = 1 as 1 | 2 | 3; i <= 3; i++) {
      if (eventImage[i]) {
        const formData = new FormData();
        formData.append("file", eventImage[i]);
        formData.append("upload_preset", presetkey); // Replace with your upload preset

        try {
          const response = await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          );
          updatedImageUrls[i] = response.data.secure_url;
        } catch (error) {}
      }
    }

    setImageUrl(updatedImageUrls);
    return updatedImageUrls;
  }

  const { showErrorMessage, setShowErrorMessage, errorMessage, validation } =
    useValidationHandler();
  const [speakers, setSpeakers] = useState<LabMemberTypes[]>([]);

  //show notification after and hide after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);
  const addEventInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: eventState.title,
    },
    {
      placeHolder: "Event date",
      type: "text",
      mainType: "date",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: eventState.date,
    },
    {
      placeHolder: "Event time",
      type: "text",
      mainType: "time",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "time";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: eventState.time,
    },
    {
      placeHolder: "Location",
      type: "text",
      mainType: "location",
      value: eventState.location,
    },
    {
      placeHolder: "Event link",
      type: "text",
      mainType: "videoLink",
      value: eventState.videoLink,
    },
    {
      placeHolder: "Non-lab speakers Seperate with comma ( , )",
      type: "text",
      mainType: "nonLabMembers",
      value: eventState.nonLabMembers,
    },
  ];

  const api = useAuthorizedApi();
  async function submitEventDetails(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(
        eventImage[1] === testImage ||
          eventImage[2] === testImage ||
          eventImage[3] === testImage,
        "Please upload all images"
      ) ||
      validation(
        eventState.title.length < 3,
        "Title must be at least 3 characters"
      ) ||
      validation(eventState.date.length === 0, "Date is required") ||
      validation(
        eventState.time.length === 0,
        "Please enter the time of the event"
      ) ||
      validation(
        eventState.location.length < 3,
        "Location must be at least 3 characters"
      ) ||
      validation(
        !eventState.videoLink.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please input a valid link"
      ) ||
      validation(
        speakers.length === 0 && !eventState.nonLabMembers,
        "A speaker is required"
      ) ||
      validation(
        eventState.about.length < 3,
        "About must be at least 3 characters"
      )
    ) {
      return;
    }

    let formattedDateTime = "";
    if (eventState.date && eventState.time) {
      const timeValue = eventState.time;
      const dateValue = eventState.date;

      const parsedDate = new Date(dateValue);
      const [hours, minutes] = timeValue.split(":");
      parsedDate?.setHours(Number(hours), Number(minutes), 0);
      formattedDateTime = parsedDate?.toISOString();
    }

    try {
      setIsLoading(true);
      const uploadedImageUrls = await handleCloudinaryUpload();
      const data = {
        title: eventState.title,
        location: eventState.location,
        link: eventState.videoLink,
        date: formattedDateTime,
        speakers: speakers.map((speaker) => {
          return { id: speaker.id };
        }),
        nonLabSpeakers: eventState.nonLabMembers.split(","),
        writeUp: eventState.about,
        images: [
          uploadedImageUrls[1],
          uploadedImageUrls[2],
          uploadedImageUrls[3],
        ],
      };
      await api.post(endpoints.addEvent, data);
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setNotificationMessage("Event Added Successfully"));
      dispatch(setErrorNotification(false));

      //Reset imputs
      dispatchEvent({
        type: "reset",
        payload: eventInitialState,
      });
      setSpeakers([]);
      dispatchEvent({ type: "about", payload: "" });
      setEventImage({
        1: testImage,
        2: testImage,
        3: testImage,
      });
    } catch (error) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setNotificationMessage("Failed to add event"));
      dispatch(setErrorNotification(true));
    }
  }

  const inputFileRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Add Event</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            {eventImages.map((image, index) => {
              return (
                <div
                  className="inputs-card-image-main-container"
                  key={index}
                  onClick={() => setEventNumber(index)}
                >
                  <div className="inputs-card-image">
                    <img
                      src={image.image}
                      alt={"lab-member"}
                      style={{
                        height: "190px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div
                    className="input-camera-button"
                    style={{ right: "9rem", bottom: "0rem" }}
                  >
                    <button
                      onClick={() => {
                        inputFileRef.current?.click();
                        setShowErrorMessage(false);
                      }}
                    >
                      <img src={camera} alt="camera" />
                    </button>
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                    name="image"
                    ref={inputFileRef}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleImageChange(event)
                    }
                  />
                </div>
              );
            })}
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEventDetails}>
            {addEventInputs.map((input, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchEvent({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  onFocus={input.focus}
                  onBlur={input.blur}
                  value={input.value}
                />
              );
            })}
            <SelectFields
              selectCaption="Select Speakers"
              participantsArray={speakers}
              setParticipantsArray={setSpeakers}
              sponsorsOrMembersArray="lab-members"
            />
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchEvent({
                  type: "about",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={eventState.about}
            />
            {isLoading ? (
              <button className="add-button">Adding...</button>
            ) : (
              <button className="add-button">Add Event</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEvent;
