import { Link } from "react-router-dom";
import "../styles/WelcomePage.scss";
import backgroundImage from "../../../assets/images/main-background-image.jpg";

function WelcomePage() {
  return (
    <>
      <div
        className="welcome-main"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="welcome-message">
          <p>
            Welcome to the Distributed IoT Platforms, Privacy, and
            Edge-Intelligence Research Lab (DIPPER)
          </p>
        </div>
        <div className="welcome-page-read-more-button">
          <Link to="/about-us">
            <button>Read More</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default WelcomePage;
