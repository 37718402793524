import { createSlice } from "@reduxjs/toolkit";
import { ProjectsType } from "../../features/Projects Page/components/Projects";

export type ProjectReducerState = {
  projectDetails: ProjectsType | null;
  projectIndex: number;
};

const initialState: ProjectReducerState = {
  projectDetails: null,
  projectIndex: 0,
};

const projectsSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    setProjectIndex: (state, action) => {
      state.projectIndex = action.payload;
    },
  },
});

export const { setProjectDetails, setProjectIndex } = projectsSlice.actions;
export default projectsSlice.reducer;
