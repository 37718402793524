import Footer from "../../../components/Footer";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import Navigation from "../../../components/Navigation";
import "../styles/LabMemberDetails.scss";
import { Link, useParams } from "react-router-dom";
import { LabMemberTypes } from "./LabMembers";
import { useState, useEffect } from "react";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { useQuery } from "react-query";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";
import { PublicationType } from "../../Publication Page/components/Publications";
import { ProjectsType } from "../../Projects Page/components/Projects";
import { Fragment } from "react";

function LabMemberDetails() {
  //fetch lab member details
  const api = useAuthorizedApi();

  const { id } = useParams<{ id: string }>();
  const [labMemberDetails, setLabMemberDetails] =
    useState<LabMemberTypes | null>(null);

  const { isFetching: isLabMembersLoading } = useQuery(
    "lab-members",
    () => {
      return api.get(endpoints.getMembers);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const labMember = response.data.members.find(
          (member: LabMemberTypes) => member.id === id
        );
        setLabMemberDetails(labMember || null);
      },
    }
  );

  //Mix up lab member projects and publications
  const [researchAndProjects, setResearchAndProjects] = useState<
    (PublicationType | ProjectsType)[]
  >([]);
  const { data: publicationsData, isLoading: isPublicationLoading } = useQuery(
    "publications",
    () => {
      return api.get(endpoints.getPublications);
    }
  );

  const { data: projectsData, isLoading: isProjectLoading } = useQuery(
    "projects",
    () => {
      return api.get(endpoints.getProjects);
    }
  );

  useEffect(() => {
    if (
      labMemberDetails &&
      publicationsData?.data?.publications &&
      projectsData?.data?.projects
    ) {
      const filteredPublications = publicationsData.data.publications.filter(
        (publication: PublicationType) =>
          publication.authors?.some(
            (author) => author.id === labMemberDetails.id
          )
      );

      const filteredProjects = projectsData.data.projects.filter(
        (project: ProjectsType) =>
          project.contributors.some(
            (contributor) => contributor.id === labMemberDetails.id
          )
      );

      const combinedResults = [...filteredPublications, ...filteredProjects];
      setResearchAndProjects(combinedResults);
    }
  }, [labMemberDetails, publicationsData, projectsData]);

  const isLoading =
    isLabMembersLoading || isPublicationLoading || isProjectLoading;


    //checking if non lab member array has en empty string.
    const isNonEmptyArray = (arr:any) =>
      Array.isArray(arr) &&
      arr.length > 0 &&
      arr.some((item) => item.trim() !== "");
  return (
    <>
      <Navigation />
      <LargeScreenContainer>
        {isLoading ? (
          <>
            <div className="skeleton-loading-main">
              <div className="skeletal-profile-main-details">
                <div className="skeletal-image-skeleton"></div>
                <div className="skeletal-profile-details">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="skeletal-about-details">
                <span className="skeletal-title"></span>
                <div className="skeletal-description">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="lab-member-details-main-container">
              <div className="lab-member-details-profile">
                <div className="lab-member-picture">
                  <img src={labMemberDetails?.image} alt="lab-member-profile" />
                </div>
                <p className="lab-member-profile-details">
                  <span className="lab-member-name">
                    {`${capitalizeFirstLetter(
                      labMemberDetails?.fname
                    )} ${capitalizeFirstLetter(
                      labMemberDetails?.otherNames
                    )} ${capitalizeFirstLetter(labMemberDetails?.lname)}` ||
                      "N/A"}
                  </span>
                  <span className="lab-member-lab-position">
                    {capitalizeFirstLetter(labMemberDetails?.labPosition) ||
                      "N/A"}
                  </span>
                  <span className="lab-member-job-position">
                    {capitalizeFirstLetter(
                      labMemberDetails?.academicPosition
                    ) || "N/A"}
                  </span>
                  <span className="lab-member-email">
                    {labMemberDetails?.email || "N/A"}
                  </span>
                </p>
              </div>
              <div className="lab-member-description">
                <h3>About</h3>
                <p>{capitalizeFirstLetter(labMemberDetails?.about) || "N/A"}</p>
              </div>
              <div className="lab-member-research-and-projects-container">
                <h3 className="lab-member-research-and-projects-header">
                  Research and Projects
                </h3>
                {researchAndProjects?.map((rp) => {
                  let linkPath: string;
                  if ((rp as PublicationType).authors !== undefined) {
                    linkPath = (rp as PublicationType).link;
                  } else {
                    linkPath = `/projects/details/${rp.id}`;
                  }

                  const nonLabAuthorsOrContributors = rp.hasOwnProperty(
                    "authors"
                  )
                    ? (rp as PublicationType).non_lab_authors
                    : (rp as ProjectsType).non_lab_contributors;

                  const hasNonLabMembers = isNonEmptyArray(
                    nonLabAuthorsOrContributors
                  );

                  return (
                    <div
                      className="lab-member-research-and-projects"
                      key={rp.id}
                    >
                      <Link
                        to={linkPath}
                        target="_blank"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <h4 className="research-and-projects-title">
                          {rp.title}
                        </h4>
                      </Link>
                      <p className="research-and-projects-authors">
                        {rp.hasOwnProperty("authors") ? (
                          <>
                            <span style={{ fontWeight: "600" }}>Authors: </span>
                            {(rp as PublicationType).authors?.map(
                              (author, index, array) => (
                                <Fragment key={author.id}>
                                  <span
                                    className="research-and-projects-author"
                                    onClick={() =>
                                      window.open(
                                        `/labmembers/details/${author.id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {`${capitalizeFirstLetter(
                                      author.fname
                                    )} ${capitalizeFirstLetter(
                                      author.otherNames
                                    )} ${capitalizeFirstLetter(author.lname)}`}
                                  </span>
                                  {index < array.length - 1 || hasNonLabMembers
                                    ? ", "
                                    : ""}
                                </Fragment>
                              )
                            )}
                            {hasNonLabMembers && (
                              <span>
                                {capitalizeFirstLetter(
                                  (nonLabAuthorsOrContributors as string[])
                                    .filter((a) => a.trim() !== "")
                                    .join(", ")
                                )}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            <span style={{ fontWeight: "500" }}>
                              Contributors:{" "}
                            </span>
                            {(rp as ProjectsType).contributors?.map(
                              (contributor, index, array) => (
                                <Fragment key={contributor.id}>
                                  <span
                                    className="research-and-projects-author"
                                    onClick={() =>
                                      window.open(
                                        `/labmembers/details/${contributor.id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {`${capitalizeFirstLetter(
                                      contributor.fname
                                    )} ${capitalizeFirstLetter(
                                      contributor.otherNames
                                    )} ${capitalizeFirstLetter(
                                      contributor.lname
                                    )}`}
                                  </span>
                                  {index < array.length - 1 || hasNonLabMembers
                                    ? ", "
                                    : ""}
                                </Fragment>
                              )
                            )}
                            {hasNonLabMembers && (
                              <span>
                                {(nonLabAuthorsOrContributors as string[])
                                  .filter((c) => c.trim() !== "")
                                  .join(", ")}
                              </span>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  );
                })}
                {researchAndProjects.length === 0 && (
                  <p className="empty-research-and-projects">
                    No research and projects available
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default LabMemberDetails;
