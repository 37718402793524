import camera from "../../../assets/icons/camera.png";
import { useRef, useReducer, useEffect, useState } from "react";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import { useDispatch, useSelector } from "react-redux";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import { useAuthorizedApi } from "../../../services/Axios";
import { useEditedData } from "../../../helpers/EdittedData";
import { endpoints } from "../../../services/Endpoints";
import { RootState } from "../../../types/GlobalTypes";
import Notification from "../../../components/Notification";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { AnimatePresence } from "framer-motion";
import ErrorMessage from "../../../components/ErrorMessage";

interface Props {
  labMemberDetails: LabMemberTypes | null;
}

function editLabMemberReducer(
  state: LabMemberTypes,
  action: any
): LabMemberTypes {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "firstname":
      return {
        ...state,
        fname: action.payload,
      };
    case "lastname":
      return {
        ...state,
        lname: action.payload,
      };
    case "otherName":
      return {
        ...state,
        otherNames: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "program":
      return {
        ...state,
        program: action.payload,
      };
    case "labPosition":
      return {
        ...state,
        labPosition: action.payload,
      };
    case "academicPosition":
      return {
        ...state,
        academicPosition: action.payload,
      };
    case "about":
      return {
        ...state,
        about: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function EditMember({ labMemberDetails }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //file input
  const inputFileRef = useRef<HTMLInputElement>(null);

  //edit lab member states
  const editLabMemberInitialState: LabMemberTypes = {
    title: labMemberDetails?.title || "",
    fname: labMemberDetails?.fname || "",
    lname: labMemberDetails?.lname || "",
    otherNames: labMemberDetails?.otherNames || "",
    email: labMemberDetails?.email || "",
    program: labMemberDetails?.program || "",
    labPosition: labMemberDetails?.labPosition || "",
    academicPosition: labMemberDetails?.academicPosition || "",
    about: labMemberDetails?.about || "",
  };

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  const [editLabMemberState, dispatchEditLabMember] = useReducer(
    editLabMemberReducer,
    editLabMemberInitialState
  );

  //edit member input fields
  const editMemberInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: editLabMemberState.title,
    },
    {
      placeHolder: "First name",
      type: "text",
      mainType: "firstname",
      value: editLabMemberState.fname,
    },
    {
      placeHolder: "Last name",
      type: "text",
      mainType: "lastname",
      value: editLabMemberState.lname,
    },
    {
      placeHolder: "Other names",
      type: "text",
      mainType: "otherName",
      value: editLabMemberState.otherNames,
    },
    {
      placeHolder: "Email",
      type: "text",
      mainType: "email",
      value: editLabMemberState.email,
    },
    {
      placeHolder: "Program",
      type: "text",
      mainType: "program",
      value: editLabMemberState.program,
    },
    {
      placeHolder: "Lab position",
      type: "text",
      mainType: "labPosition",
      value: editLabMemberState.labPosition,
    },
    {
      placeHolder: "Academic position",
      type: "text",
      mainType: "academicPosition",
      value: editLabMemberState.academicPosition,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();

  //Submit editted lab member details
  const api = useAuthorizedApi();
  const originalData = {
    ...editLabMemberInitialState,
    image: labMemberDetails?.image || "",
  };
  const newData = {
    fname: editLabMemberState.fname,
    lname: editLabMemberState.lname,
    otherNames: editLabMemberState.otherNames,
    email: editLabMemberState.email,
    title: editLabMemberState.title,
    program: editLabMemberState.program,
    labPosition: editLabMemberState.labPosition,
    academicPosition: editLabMemberState.academicPosition,
    image: image,
    about: editLabMemberState.about,
  };

  // Initialize image state with the existing sponsor image
  useEffect(() => {
    if (labMemberDetails?.image) {
      setImage(labMemberDetails?.image);
    }
  }, [labMemberDetails, setImage]);

  //compare new data and old data to check which input fields have changed
  const { getChangedData } = useEditedData();
  const editedData = getChangedData(originalData, newData);

  async function submitEdittedMemberDetails(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (
      validation(
        editLabMemberState.title.length < 2,
        "Please type a valid title"
      ) ||
      validation(
        editLabMemberState.fname.length < 2,
        "Firstname should be more than 2 characters"
      ) ||
      validation(
        editLabMemberState.lname.length < 2,
        "Lastname should be more than 2 characters"
      ) ||
      validation(
        !editLabMemberState.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        "Please type a valid email"
      ) ||
      validation(
        editLabMemberState.program.length < 3,
        "Program should be more than 3 characters"
      ) ||
      validation(
        editLabMemberState.labPosition.length < 3,
        "Lab position should be more than 3 characters"
      ) ||
      validation(
        editLabMemberState.academicPosition.length < 3,
        "Job position should be more than 3 characters"
      ) ||
      validation(
        editLabMemberState.about.length < 3,
        "About should be more than 3 characters"
      )
    ) {
      return;
    }

    if (Object.keys(editedData).length > 0) {
      try {
        setIsLoading(true);
        let imageUrl: string | null = originalData.image;
        if (image !== originalData.image) {
          imageUrl = await handleCloudinaryUpload(image);
          if (imageUrl) {
            editedData.image = imageUrl;
          }
        }
        await api.patch(
          endpoints.editLabMember(labMemberDetails?.id),
          editedData
        );
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(false));
        dispatch(setNotificationMessage("Lab Member edited successfully"));
      } catch (error: any) {
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage(error.response.data.message));
        } else {
          dispatch(setNotificationMessage("Failed to edit sponsor"));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  
  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Edit Lab Member</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div
                className="inputs-card-image"
                style={{
                  border: "none",
                }}
              >
                <img
                  src={image}
                  alt="lab-member"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "15rem", bottom: "2rem" }}
              >
                <button onClick={() => inputFileRef.current?.click()}>
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            </div>
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEdittedMemberDetails}>
            {editMemberInputs.map((input, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchEditLabMember({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  value={input.value}
                />
              );
            })}
            <textarea
              placeholder={"about"}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchEditLabMember({
                  type: "about",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={editLabMemberState.about}
            />
            {isLoading ? (
              <button className="add-button">Saving...</button>
            ) : (
              <button className="add-button">Save</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditMember;
