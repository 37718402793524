import { useEffect, useReducer, useRef, useState } from "react";
import Notification from "../../../components/Notification";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { AnimatePresence } from "framer-motion";
import camera from "../../../assets/icons/camera.png";
import ErrorMessage from "../../../components/ErrorMessage";
import testImage from "../../../assets/images/default-image.jpg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import SelectFields from "../../../components/SelectFields";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";

type PublicationType = {
  title: string;
  abstract: string;
  link: string;
  publicationDate: string;
  nonLabAuthors: string;
};
function publicationReducer(
  state: PublicationType,
  action: any
): PublicationType {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.payload,
      };
    case "abstract":
      return {
        ...state,
        abstract: action.payload,
      };
    case "link":
      return {
        ...state,
        link: action.payload,
      };
    case "publicationDate":
      return {
        ...state,
        publicationDate: action.payload,
      };
    case "nonLabAuthors":
      return {
        ...state,
        nonLabAuthors: action.payload,
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}
function AddPublication() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authors, setAuthors] = useState<LabMemberTypes[]>([]);
  const dispatch = useDispatch();

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  const publicationInitialState: PublicationType = {
    title: "",
    abstract: "",
    link: "",
    publicationDate: "",
    nonLabAuthors: "",
  };
  const [publicationState, dispatchPublication] = useReducer(
    publicationReducer,
    publicationInitialState
  );

  const addPublicationInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: publicationState.title,
    },
    {
      placeHolder: "Published Date",
      type: "text",
      mainType: "publicationDate",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: publicationState.publicationDate,
    },
    {
      placeHolder: "Source link",
      type: "text",
      mainType: "link",
      value: publicationState.link,
    },
    {
      placeHolder: "Non-Lab Authors. Seperate with a comma ( , )",
      type: "text",
      mainType: "nonLabAuthors",
      value: publicationState.nonLabAuthors,
    },
  ];

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const inputFileRef = useRef<HTMLInputElement>(null);

  //Submit publication details
  const api = useAuthorizedApi();
  async function submitPublicationDetails(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (
      validation(image === testImage, "Please upload an image") ||
      validation(
        publicationState.title.length < 3,
        "Title should be at least 3 characters"
      ) ||
      validation(
        publicationState.publicationDate.length === 0,
        "Please input a date"
      ) ||
      validation(
        !publicationState.link.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please input a valid link"
      ) ||
      validation(
        authors.length === 0 && publicationState.nonLabAuthors.length === 0,
        "An author is required"
      ) ||
      validation(
        publicationState.abstract.length < 3,
        "Abstract should be at least 3 characters"
      )
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const uploadedUrl = await handleCloudinaryUpload(image);

      const data = {
        title: publicationState.title,
        abstract: publicationState.abstract,
        link: publicationState.link,
        publicationDate: publicationState.publicationDate,
        image: uploadedUrl,
        authors: authors.map((author) => {
          return { id: author.id };
        }),
        nonLabAuthors: publicationState.nonLabAuthors.split(","),
      };

      await api.post(endpoints.addPublication, data);
      setIsLoading(false);
      setImage(testImage);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(setNotificationMessage("Publication added successfully"));
      dispatchPublication({ type: "reset", payload: publicationInitialState });
      setAuthors([]);
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage("Failed to add publication"));
      } else if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Add Publication</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            <div className="inputs-card-image-main-container">
              <div className="inputs-card-image">
                <img
                  src={image}
                  alt={"lab-member"}
                  style={{
                    height: "190px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                className="input-camera-button"
                style={{ right: "9rem", bottom: "0rem" }}
              >
                <button
                  onClick={() => {
                    inputFileRef.current?.click();
                    setShowErrorMessage(false);
                  }}
                >
                  <img src={camera} alt="camera" />
                </button>
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                ref={inputFileRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleImageChange(event)
                }
              />
            </div>
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitPublicationDetails}>
            {addPublicationInputs.map((input, index) => {
              return (
                <input
                  placeholder={input.placeHolder}
                  type={input.type}
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchPublication({
                      type: input.mainType,
                      payload: event.target.value,
                    });
                    setShowErrorMessage(false);
                  }}
                  onFocus={input.focus}
                  onBlur={input.blur}
                  value={input.value}
                />
              );
            })}
            <SelectFields
              selectCaption="Select authors..."
              participantsArray={authors}
              setParticipantsArray={setAuthors}
              sponsorsOrMembersArray="lab-members"
            />
            <textarea
              placeholder="Abstract"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                dispatchPublication({
                  type: "abstract",
                  payload: event.target.value,
                });
                setShowErrorMessage(false);
              }}
              value={publicationState.abstract}
            />
            {isLoading ? (
              <button className="add-button">Adding...</button>
            ) : (
              <button className="add-button">Add publication</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddPublication;
