import "../../../styles/_workDetails.scss";
import Navigation from "../../../components/Navigation";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import { Fragment, useState } from "react";
import { ProjectsType } from "./Projects";
import { useAuthorizedApi } from "../../../services/Axios";
import { useQuery } from "react-query";
import { endpoints } from "../../../services/Endpoints";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";

function ProjectDetails() {
  const { id } = useParams<{ id: string }>();

  //fetch project details
  const [projectDetails, setProjectDetails] = useState<ProjectsType | null>();

  //Fecthing projects and filtering by id
  const api = useAuthorizedApi();
  const { isFetching } = useQuery(
    "projects",
    () => api.get(endpoints.getProjects),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const project = response.data.projects.find(
          (proj: ProjectsType) => proj.id === id
        );
        setProjectDetails(project || null);
      },
    }
  );

  //Convert date to normal format
  let startDate = "";
  let endDate = "";

  if (projectDetails) {
    const projectStartDate = new Date(projectDetails.startDate);
    const projectEndDate = new Date(projectDetails.endDate);

    startDate = projectStartDate.toLocaleDateString("default", {
      month: "long",
      year: "numeric",
      day: "numeric",
    });

    endDate = projectEndDate.toLocaleDateString("default", {
      month: "long",
      year: "numeric",
      day: "numeric",
    });
  }

  //checking is non lab contributors contains an empty string
  const isNonEmptyArray = (arr: any) =>
    Array.isArray(arr) &&
    arr.length > 0 &&
    arr.some((item) => {
      if (typeof item === "string") {
        return item.trim() !== "";
      }
      return item != null;
    });

  return (
    <>
      <Navigation />
      <LargeScreenContainer>
        {isFetching ? (
          <>
            <div className="skeleton-loading-main">
              <div className="skeletal-header" />
              <div className="skeletal-image">
                <div className="skeletal-image-skeleton"></div>
              </div>
              <div className="skeletal-details">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="skeletal-details-description">
                <span className="skeletal-title"></span>
                <div className="skeletal-description">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="project-details-main">
              <div className="project-details-header">
                <h1>{capitalizeFirstLetter(projectDetails?.title)}</h1>
              </div>
              <div className="project-details-image">
                <img src={projectDetails?.image} alt="project" />
              </div>
              <div className="extra-project-details">
                <p className="project-contributers">
                  Contributors:{" "}
                  {isNonEmptyArray(projectDetails?.contributors) ||
                  isNonEmptyArray(projectDetails?.non_lab_contributors) ? (
                    <>
                      {projectDetails?.contributors?.map(
                        (contributor, index, array) => (
                          <Fragment key={contributor.id}>
                            <span
                              onClick={() =>
                                window.open(
                                  `/labmembers/details/${contributor.id}`,
                                  "_blank"
                                )
                              }
                              className="lab-contributers"
                            >
                              {`${capitalizeFirstLetter(
                                contributor.fname
                              )} ${capitalizeFirstLetter(contributor.lname)}`}
                            </span>
                            {index < array.length - 1 ||
                            isNonEmptyArray(projectDetails.non_lab_contributors)
                              ? ", "
                              : ""}
                          </Fragment>
                        )
                      )}
                      {isNonEmptyArray(
                        projectDetails?.non_lab_contributors
                      ) && (
                        <span>
                          {projectDetails?.non_lab_contributors
                            ? Array.isArray(projectDetails.non_lab_contributors)
                              ? capitalizeFirstLetter(
                                  projectDetails.non_lab_contributors.join(", ")
                                )
                              : capitalizeFirstLetter(
                                  projectDetails.non_lab_contributors
                                )
                            : ""}
                        </span>
                      )}
                    </>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p className="project-start-date">
                  Start Date: <span>{startDate || "N/A"}</span>
                </p>
                <p className="project-end-date">
                  End Date: <span>{endDate || "N/A"}</span>
                </p>
                <p className="project-sponsors">
                  {projectDetails?.sponsors.length === 0 ? (
                    <>
                      Sponsors: <span>N/A</span>
                    </>
                  ) : (
                    <>
                      Sponsors:{" "}
                      {projectDetails?.sponsors.map((sponsor, index) => {
                        return (
                          <span
                            onClick={() => window.open(sponsor.url, "_blank")}
                            className="project-sponsor"
                            key={sponsor.id}
                          >
                            {capitalizeFirstLetter(sponsor.name)}
                            {/* Add a comma if it's not the last sponsor */}
                            {index < projectDetails.sponsors.length - 1 && ", "}
                          </span>
                        );
                      })}
                    </>
                  )}
                </p>
                <p className="project-funding">
                  Funding:{" "}
                  <span>
                    {projectDetails?.funding === 0
                      ? "N/A"
                      : ` ${projectDetails?.currency}${projectDetails?.funding}`}
                  </span>
                </p>
              </div>
              <div className="project-details-description">
                <h3>Project Description</h3>
                <p>
                  {projectDetails && projectDetails.description.length > 0 ? (
                    <>{capitalizeFirstLetter(projectDetails?.description)}</>
                  ) : (
                    <>N/A</>
                  )}
                </p>
              </div>
              <div className="project-details-button">
                <Link to={"/projects"}>
                  <button>Back to Projects</button>
                </Link>
              </div>
            </div>
          </>
        )}
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default ProjectDetails;
