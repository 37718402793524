import dropdown from "../assets/icons/dropdown.png";
import { useEffect, useRef, useState } from "react";
import close from "../assets/icons/close.png";
import { useQuery } from "react-query";
import { authenticationApi } from "../services/Axios";
import { endpoints } from "../services/Endpoints";
import { LabMemberTypes } from "../features/Lab Members Page/components/LabMembers";
import { SponsorTypes } from "../features/Home Page/components/OurPartners";
import { capitalizeFirstLetter } from "../helpers/CapitalizeFirstLetter";

interface Props {
  participantsArray: LabMemberTypes[] | SponsorTypes[] | undefined;
  setParticipantsArray: React.Dispatch<React.SetStateAction<any[]>>;
  selectCaption: string;
  sponsorsOrMembersArray: "lab-members" | "sponsors";
}

function SelectFields({
  selectCaption,
  participantsArray,
  setParticipantsArray,
  sponsorsOrMembersArray,
}: Props) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [participants, setParticipants] = useState<
    (LabMemberTypes | SponsorTypes)[]
  >([]);

  function hideDropdown() {
    setShowDropdown((prev) => !prev);
  }

  // Get members or sponsors
  // Define the query key and query function based on sponsorsOrMembersArray
  const queryKey =
    sponsorsOrMembersArray === "lab-members" ? "lab-members" : "sponsors";
  const queryFn =
    sponsorsOrMembersArray === "lab-members"
      ? () => authenticationApi.get(endpoints.getMembers)
      : () => authenticationApi.get(endpoints.getSponsors);

  // Use a single useQuery for both lab members and sponsors
  useQuery(queryKey, queryFn, {
    onSuccess: (response) => {
      setParticipants(
        sponsorsOrMembersArray === "lab-members"
          ? response.data.members
          : response.data.sponsors
      );
    },
  });

  //click outside to close dropdown
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="select-fields">
      <div className="select-option-view">
        {!participantsArray || participantsArray.length === 0 ? (
          <span className="select-option-placeholder">
            {selectCaption}
            <img
              src={dropdown}
              alt="dropdown"
              onClick={hideDropdown}
              style={{ cursor: "pointer" }}
            />
          </span>
        ) : (
          <div className="item-list">
            {participantsArray.map((participant) => (
              <span key={participant.id}>
                {sponsorsOrMembersArray === "lab-members"
                  ? `${capitalizeFirstLetter(
                      (participant as LabMemberTypes).title
                    )} ${capitalizeFirstLetter(
                      (participant as LabMemberTypes).fname
                    )} ${capitalizeFirstLetter(
                      (participant as LabMemberTypes).lname
                    )}`
                  : `${capitalizeFirstLetter(
                      (participant as SponsorTypes).name
                    )}`}
                <img
                  src={close}
                  alt=""
                  onClick={() => {
                    setParticipantsArray(
                      (
                        participantsArray as (LabMemberTypes | SponsorTypes)[]
                      ).filter((p) => p.id !== participant.id)
                    );
                  }}
                />
              </span>
            ))}
            <img
              src={dropdown}
              alt="dropdown"
              onClick={hideDropdown}
              style={{ cursor: "pointer" }}
              className="dropdown-icon"
            />
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="select-dropdown" ref={dropdownRef}>
          {participants.map((participant) => (
            <span
              key={participant.id}
              onClick={() => {
                if (
                  participantsArray &&
                  !participantsArray.some((p) => p.id === participant.id)
                ) {
                  setParticipantsArray([...participantsArray, participant]);
                }
                setShowDropdown(false);
              }}
            >
              {sponsorsOrMembersArray === "lab-members"
                ? `${capitalizeFirstLetter(
                    (participant as LabMemberTypes).title
                  )} ${capitalizeFirstLetter(
                    (participant as LabMemberTypes).fname
                  )} ${capitalizeFirstLetter(
                    (participant as LabMemberTypes).otherNames
                  )} ${capitalizeFirstLetter(
                    (participant as LabMemberTypes).lname
                  )}`
                : `${capitalizeFirstLetter(
                    (participant as SponsorTypes).name
                  )}`}
            </span>
          ))}
          {participants.length === 0 && (
            <span>
              No{" "}
              {sponsorsOrMembersArray === "lab-members"
                ? "lab members"
                : "sponsors"}{" "}
              found
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectFields;
