import { Link } from "react-router-dom";
import "../styles/Publications.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import EditPublication from "../../Admin/components/EditPublication";
import Delete from "../../Admin/components/Delete";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { useQuery } from "react-query";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";
import { AnimatePresence, motion } from "framer-motion";
import Notification from "../../../components/Notification";

export type PublicationType = {
  id?: string;
  title: string;
  abstract: string;
  link: string;
  publicationDate: string;
  image?: string;
  authors?: LabMemberTypes[];
  non_lab_authors: string[] | string;
};

function Publications() {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [publicationState, setPublicationState] = useState<{
    main: PublicationType[];
    details: PublicationType | null;
  }>({
    main: [],
    details: null,
  });
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const [editPublication, setEditPublication] = useState<boolean>(false);
  const [deletePublication, setDeletePublication] = useState<boolean>(false);

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );
  //fetching publications
  const api = useAuthorizedApi();
  const { isFetching, refetch } = useQuery(
    "projects",
    () => {
      return api.get(endpoints.getPublications);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setPublicationState({
          ...publicationState,
          main: response.data.publications,
        });
      },
    }
  );

  //Delete publication
  async function submitDeletePublication(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    try {
      setDeleteLoading(true);
      await api.delete(
        endpoints.deletePublication(publicationState.details?.id)
      );
      setDeleteLoading(false);
      setDeletePublication(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${publicationState.details?.title} deleted successfully`
        )
      );
      refetch();
    } catch (error: any) {
      console.log(error);
      setDeleteLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to delete publication"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      {editPublication && (
        <Modal onClose={() => setEditPublication(false)}>
          <EditPublication publicationDetails={publicationState.details} />
        </Modal>
      )}
      {deletePublication && (
        <Modal onClose={() => setDeletePublication(false)}>
          <Delete
            deleteFunction={submitDeletePublication}
            isLoading={deleteLoading}
            name={publicationState.details?.title}
            close={() => setDeletePublication(false)}
            type="delete"
          />
        </Modal>
      )}
      <div className="publications-main-container">
        <div className="publications-header">
          <h1>Publications</h1>
        </div>
        <div className="publications-list-container">
          {isFetching ? (
            <>
              {[0, 1, 2, 3, 4].map((_, index) => {
                return (
                  <div className="skeletal-loading-main-container" key={index}>
                    <div className="skeletal-dummy-image"></div>
                    <div className="skeletal-dummy-title"></div>
                    <div className="skeletal-dummy-description">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <AnimatePresence initial={false}>
                {publicationState.main && publicationState.main.length > 0 ? (
                  <>
                    {publicationState?.main?.map((publication, index) => {
                      const shortenedDescription = publication.abstract
                        .trim()
                        .substring(0, 250);
                      return (
                        <motion.div
                          className="publication-main"
                          key={index}
                          onClick={() =>
                            setPublicationState({
                              ...publicationState,
                              details: publication,
                            })
                          }
                        >
                          <div className="publication-image">
                            <img
                              src={publication.image}
                              alt={publication.title}
                              loading="lazy"
                            />
                          </div>
                          <Link to={`/publications/details/${publication.id}`}>
                            <h4 className="publication-title">
                              {capitalizeFirstLetter(
                                publication.title.trim().substring(0, 50)
                              ) + "..."}
                            </h4>
                          </Link>
                          <p className="publication-description">
                            {capitalizeFirstLetter(
                              shortenedDescription + "..."
                            )}
                          </p>
                          {isLoggedIn && (
                            <div
                              className="publication-admin-buttons"
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <button onClick={() => setEditPublication(true)}>
                                Edit
                              </button>

                              <button
                                onClick={() => setDeletePublication(true)}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </motion.div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="empty-message">
                      <p>No publications available</p>
                    </div>
                  </>
                )}
              </AnimatePresence>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Publications;
