import "../styles/ErrorMessage.scss";

interface Props {
  errorMessage: string;
}

/**
 * A React Component that displays an error message. Use this component when an error occurs in your form. Pass the error message you want to display as a prop.
 */
function ErrorMessage({ errorMessage }: Props) {
  return <div className="inputs-error-message">{errorMessage}</div>;
}

export default ErrorMessage;
