import { Link, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { PublicationType } from "./Publications";
import { useAuthorizedApi } from "../../../services/Axios";
import { useQuery } from "react-query";
import { endpoints } from "../../../services/Endpoints";
import Navigation from "../../../components/Navigation";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";
import Footer from "../../../components/Footer";

export default function PublicationDetails() {
  const { id } = useParams();

  //fetch project details
  const [publicationDetails, setPublicationDetails] =
    useState<PublicationType | null>();

  //Fecthing projects and filtering by id
  const api = useAuthorizedApi();
  const { isFetching } = useQuery(
    "publication",
    () => api.get(endpoints.getPublications),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const publication = response.data.publications.find(
          (pub: PublicationType) => pub.id === id
        );
        setPublicationDetails(publication || null);
      },
    }
  );

  let publicationDate;
  if (publicationDetails) {
    publicationDate = new Date(
      publicationDetails?.publicationDate
    ).toLocaleDateString("default", {
      month: "long",
      year: "numeric",
      day: "numeric",
    });
  }

  //checking is non lab authors contains an empty string
  const isNonEmptyArray = (arr: any) =>
    Array.isArray(arr) &&
    arr.length > 0 &&
    arr.some((item) => {
      if (typeof item === "string") {
        return item.trim() !== "";
      }
      return item != null;
    });

  return (
    <>
      <Navigation />
      <LargeScreenContainer>
        {isFetching ? (
          <>
            <div className="skeleton-loading-main">
              <div className="skeletal-header" />
              <div className="skeletal-image">
                <div className="skeletal-image-skeleton"></div>
              </div>
              <div className="skeletal-details">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="skeletal-details-description">
                <span className="skeletal-title"></span>
                <div className="skeletal-description">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="publication-details-main">
              <div className="publication-details-header">
                <h1>
                  {capitalizeFirstLetter(publicationDetails?.title) || "N/A"}
                </h1>
              </div>
              <div className="publication-details-image">
                <img src={publicationDetails?.image} alt="project" />
              </div>
              <div className="extra-publication-details">
                <p className="publication-authors">
                  Authors:{" "}
                  {isNonEmptyArray(publicationDetails?.authors) ||
                  isNonEmptyArray(publicationDetails?.non_lab_authors) ? (
                    <>
                      {publicationDetails?.authors?.map(
                        (author, index, array) => (
                          <Fragment key={author.id}>
                            <span
                              onClick={() =>
                                window.open(
                                  `/labmembers/details/${author.id}`,
                                  "_blank"
                                )
                              }
                              className="authors"
                            >
                              {`${capitalizeFirstLetter(
                                author.fname
                              )} ${capitalizeFirstLetter(author.lname)}`}
                            </span>
                            {index < array.length - 1 ||
                            isNonEmptyArray(publicationDetails.non_lab_authors)
                              ? ", "
                              : ""}
                          </Fragment>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <span>N/A</span>
                    </>
                  )}
                  <span>
                    {publicationDetails?.non_lab_authors
                      ? Array.isArray(publicationDetails.non_lab_authors)
                        ? capitalizeFirstLetter(
                            publicationDetails.non_lab_authors.join(", ")
                          )
                        : capitalizeFirstLetter(
                            publicationDetails.non_lab_authors
                          )
                      : ""}
                  </span>
                </p>
                <p className="publication-published-date">
                  Published Date: <span>{publicationDate || "N/A"}</span>
                </p>
                <div className="publication-source-button">
                  <button>
                    <Link to={`${publicationDetails?.link}`} target="_blank">
                      Source
                    </Link>
                  </button>
                </div>
              </div>
              <div className="publication-details-abstract">
                <h3>Abstract</h3>
                <p>
                  {publicationDetails &&
                  publicationDetails.abstract.length > 0 ? (
                    <>{capitalizeFirstLetter(publicationDetails?.abstract)}</>
                  ) : (
                    <>N/A</>
                  )}
                </p>
              </div>
              <div className="publication-details-button">
                <Link to={"/publications"}>
                  <button>Back to Publications</button>
                </Link>
              </div>
            </div>
          </>
        )}
      </LargeScreenContainer>
      <Footer />
    </>
  );
}
