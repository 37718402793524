import Footer from "../../../components/Footer";
import Navigation from "../../../components/Navigation";
import "../styles/EventDetails.scss";
import { Link } from "react-router-dom";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import { Fragment, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { NewsAndEventsType } from "./Events";
import { useAuthorizedApi } from "../../../services/Axios";
import { useQuery } from "react-query";
import { endpoints } from "../../../services/Endpoints";
import { capitalizeFirstLetter } from "../../../helpers/CapitalizeFirstLetter";

function EventDetails() {
  const { id } = useParams<{ id: string }>();
  const [eventDetails, setEventDetails] = useState<NewsAndEventsType | null>(
    null
  );
  //Carousel
  const [positionIndex, setPositionIndex] = useState<any>([0, 1, 2]);
  const [activeIndex, setActiveIndex] = useState(1);
  const rightButton = () => {
    setPositionIndex((prev: any) => {
      const updatedIndexes = prev.map((index: any) => (index + 1) % 3);
      setActiveIndex((activeIndex + 1) % 3);
      return updatedIndexes;
    });
  };

  const leftButton = () => {
    setPositionIndex((prevIndexes: any) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex: any) => (prevIndex + 2) % 3
      );
      setActiveIndex((activeIndex + 2) % 3);
      return updatedIndexes;
    });
  };

  //Handle Dot click
  const handleDotClick = (index: number) => {
    if (index !== activeIndex) {
      const newPositionIndex = positionIndex.map((_: any, idx: number) => {
        return (index - 1 + idx + 3) % 3; // Calculate new positions
      });
      setPositionIndex(newPositionIndex);
      setActiveIndex(index);
    }
  };
  const positions = ["left", "center", "right"];

  const smallerScreenVariants = {
    left: {
      x: "-60%",
      opacity: 0,
    },
    center: {
      x: "0%",
      scale: 1,
      opacity: 1,
    },
    right: {
      x: "60%",
      opacity: 0,
    },
  };
  const imageVariants = {
    left: { x: "-60%", scale: 0.9, zIndex: 2 },
    center: { x: "0%", scale: 1.1, zIndex: 5 },
    right: { x: "60%", scale: 0.9, zIndex: 1 },
  };

  //Fecthing events and filtering by id
  const api = useAuthorizedApi();
  const { isFetching } = useQuery(
    "projects",
    () => api.get(endpoints.getNewsAndEvents),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const events = response.data.events.find(
          (event: NewsAndEventsType) => event.id === id
        );
        setEventDetails(events || null);
      },
    }
  );

  //Get date
  let date;
  let time;
  if (eventDetails) {
    date = new Date(eventDetails?.date).toDateString();
    time = new Date(eventDetails?.date).toLocaleTimeString();
  }

  //checking is non lab speakers contains an empty string
  const isNonEmptyArray = (arr: any) =>
    Array.isArray(arr) &&
    arr.length > 0 &&
    arr.some((item) => {
      if (typeof item === "string") {
        return item.trim() !== "";
      }
      return item != null;
    });

  return (
    <>
      <Navigation />
      <LargeScreenContainer>
        {isFetching ? (
          <>
            <div className="skeleton-loading-main">
              <div className="skeletal-header" />
              <div className="skeletal-image">
                <div className="skeletal-image-skeleton"></div>
              </div>
              <div className="skeletal-details">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="skeletal-details-description">
                <span className="skeletal-title"></span>
                <div className="skeletal-description">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="event-details-main-container">
            <h1 className="event-details-header">
              {eventDetails?.title || "N/A"}
            </h1>
            <div
              className="event-details-images"
              style={{ position: "relative" }}
            >
              <AnimatePresence initial={false}>
                {(eventDetails &&
                  eventDetails.images &&
                  eventDetails.images.map((image, index) => {
                    return (
                      <motion.img
                        src={image}
                        alt="event"
                        key={index}
                        initial="center"
                        animate={positions[positionIndex[index]]}
                        variants={
                          window.innerWidth < 1300
                            ? smallerScreenVariants
                            : imageVariants
                        }
                        transition={{ duration: 0.5 }}
                        style={{
                          width: "40%",
                          height: "100%",
                          position: "absolute",
                          objectFit: "contain",
                        }}
                      />
                    );
                  })) ||
                  "N/A"}
              </AnimatePresence>
            </div>

            <div className="event-details-image-slider-controls">
              <span className="left" onClick={leftButton}>
                {"<"}
              </span>
              {(eventDetails &&
                eventDetails.images &&
                eventDetails.images.map((_, index) => {
                  return (
                    <span
                      key={index}
                      className={`dot ${index === activeIndex ? "active" : ""}`}
                      onClick={() => handleDotClick(index)}
                    />
                  );
                })) ||
                ""}
              <span className="right" onClick={rightButton}>
                {">"}
              </span>
            </div>
            <div className="event-details-info">
              <p className="event-details-date">
                Date: <span>{date || "N/A"}</span>
              </p>
              <p className="event-details-time">
                Time: <span>{time || "N/A"}</span>
              </p>
              <p className="event-details-location">
                Location: <span>{eventDetails?.location || "N/A"}</span>
              </p>
              <p className="event-details-speaker">
                Speakers:{" "}
                {isNonEmptyArray(eventDetails?.speakers) ||
                isNonEmptyArray(eventDetails?.non_lab_speakers) ? (
                  <>
                    {eventDetails?.speakers?.map((speaker, index, array) => (
                      <Fragment key={speaker.id}>
                        <span
                          onClick={() =>
                            window.open(
                              `/labmembers/details/${speaker.id}`,
                              "_blank"
                            )
                          }
                          className="event-details-speaker-name"
                        >
                          {`${capitalizeFirstLetter(
                            speaker.fname
                          )} ${capitalizeFirstLetter(speaker.lname)}`}
                        </span>
                        {index < array.length - 1 ||
                        isNonEmptyArray(eventDetails.non_lab_speakers)
                          ? ", "
                          : ""}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <span>N/A</span>
                  </>
                )}
                <span>
                  {capitalizeFirstLetter(
                    (eventDetails?.non_lab_speakers as string[]).join(",")
                  )}
                </span>
              </p>
              <div className="event-details-video">
                {(eventDetails?.link && (
                  <button>
                    <Link
                      to={eventDetails.link}
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <span>Learn more</span>
                    </Link>
                  </button>
                )) ||
                  "N/A"}
              </div>
            </div>
            <div className="event-details-description">
              <h2>About</h2>
              <p>{capitalizeFirstLetter(eventDetails?.writeUp) || "N/A"}</p>
            </div>
            <div className="event-details-back-to-events-button">
              <Link to={"/events"}>
                <button>Back to events</button>
              </Link>
            </div>
          </div>
        )}
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default EventDetails;
