import React, { useState } from "react";
import "../styles/AdminComponentStyles.scss";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { useRef, useEffect } from "react";
import camera from "../../../assets/icons/camera.png";
import { RootState } from "../../../types/GlobalTypes";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../../../components/ErrorMessage";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import testImage from "../../../assets/images/default-image.jpg";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";

function AddSponsor() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorUrl, setSponsorUrl] = useState<string>("");

  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const sponsorInputs = [
    {
      placeHolder: "Name",
      type: "text",
      value: sponsorName,
      onChangeFunction(event: React.ChangeEvent<HTMLInputElement>) {
        setSponsorName(event.target.value);
      },
    },
    {
      placeHolder: "Url",
      type: "text",
      value: sponsorUrl,
      onChangeFunction(event: React.ChangeEvent<HTMLInputElement>) {
        setSponsorUrl(event.target.value);
      },
    },
  ];

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //submit sponsor
  const api = useAuthorizedApi();
  async function submitSponsor(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    if (
      validation(image === testImage, "Please upload an image") ||
      validation(sponsorName.length === 0, "Please type a sponsor name ") ||
      validation(
        !sponsorUrl.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please enter a valid url"
      )
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const uploadedUrl = await handleCloudinaryUpload(image);
      const data = {
        name: sponsorName,
        image: uploadedUrl,
        url: sponsorUrl,
      };
      await api.post(endpoints.addSponsor, data);
      setImage(testImage);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(setNotificationMessage("Sponsor added successfully"));
      setIsLoading(false);
      setSponsorName("");
      setSponsorUrl("");
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage("Failed to add sponsor"));
      } else if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="add-sponsor-main-container">
        <div className="add-sponsor-card-header">Add Sponsor</div>
        <div className="inputs-card-images-container">
          <div
            className="inputs-card-image-main-container"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <div className="inputs-card-image">
              <img
                src={image}
                alt={"lab-member"}
                style={{
                  height: "200px",
                  width: "300px",
                  objectFit: "contain",

                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className="input-camera-button"
              style={{ right: "9.5rem", bottom: "0rem" }}
            >
              <button
                onClick={() => {
                  inputFileRef.current?.click();
                  setShowErrorMessage(false);
                }}
              >
                <img src={camera} alt="camera" />
              </button>
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputFileRef}
              accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleImageChange(event)
              }
            />
          </div>
        </div>
        <div className="add-sponsor-card-form-inputs-container">
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitSponsor}>
            {sponsorInputs.map((sponsor, index) => {
              return (
                <input
                  type={sponsor.type}
                  placeholder={sponsor.placeHolder}
                  value={sponsor.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    sponsor.onChangeFunction(event);
                    setShowErrorMessage(false);
                  }}
                  key={index}
                />
              );
            })}
            {isLoading ? (
              <button>Adding...</button>
            ) : (
              <button>Add Sponsor</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSponsor;
