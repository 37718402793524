import "../styles/OurMission.scss";
import dipperVideo from "../../../assets/videos/dipper-lab-video.mp4";

function OurMission() {
  return (
    <div className="our-mission-main-container">
      <div className="our-mission-info">
        <h2>Our Mission</h2>
        <p>
          To leverage IoT, blockchain, and edge-intelligence computing to
          develop innovative solutions for industries, driving transparency,
          efficiency, and sustainability
        </p>
      </div>
      <div className="our-mission-video">
        <video src={dipperVideo} controls />
      </div>
    </div>
  );
}

export default OurMission;
