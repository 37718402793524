import { createSlice } from "@reduxjs/toolkit";

export type AuthState = {
  token: string | null;
  isLoggedIn: boolean | null;
  autoLogoutPopup: boolean;
  expiredTime: string | null;
};

const initialState: AuthState = {
  token: localStorage.getItem("token"),
  isLoggedIn: !!localStorage.getItem("token"),
  autoLogoutPopup: false,
  expiredTime: localStorage.getItem("expiredTime"),
};

const AuthSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setAutoLogoutPopup: (state, action) => {
      state.autoLogoutPopup = action.payload;
    },
    setExpiredTime: (state, action) => {
      state.expiredTime = action.payload;
    },
  },
});

export const { setToken, setIsLoggedIn, setAutoLogoutPopup, setExpiredTime } =
  AuthSlice.actions;
export default AuthSlice.reducer;
