import { useReducer, useState, useRef, useEffect } from "react";
import { NewsAndEventsType } from "../../Events Page/components/Events";
import SelectFields from "../../../components/SelectFields";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import axios from "axios";
import ErrorMessage from "../../../components/ErrorMessage";
import camera from "../../../assets/icons/camera.png";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";

interface Props {
  eventDetails: NewsAndEventsType | null;
}

function editEventReducer(
  state: NewsAndEventsType,
  action: any
): NewsAndEventsType {
  switch (action.type) {
    case "title":
      return { ...state, title: action.payload };
    case "date":
    case "time":
      const currentDate = new Date(state.date);
      if (action.type === "date") {
        const [year, month, day] = action.payload.split("-");
        currentDate.setFullYear(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
      } else if (action.type === "time") {
        const [hours, minutes] = action.payload.split(":");
        currentDate.setHours(parseInt(hours), parseInt(minutes));
      }
      return { ...state, date: currentDate.toISOString() };
    case "location":
      return { ...state, location: action.payload };
    case "link":
      return { ...state, link: action.payload };
    case "writeUp":
      return { ...state, writeUp: action.payload };
    case "nonLabSpeakers":
      return {
        ...state,
        non_lab_speakers: action.payload,
      };
    default:
      return state;
  }
}

function EditEvent({ eventDetails }: Props) {
  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showErrorMessage, setShowErrorMessage, errorMessage, validation } =
    useValidationHandler();
  const [speakers, setSpeakers] = useState<LabMemberTypes[]>(
    eventDetails?.speakers || []
  );

  const editEventInitialState: NewsAndEventsType = {
    date: eventDetails?.date || new Date().toISOString(),
    title: eventDetails?.title || "",
    location: eventDetails?.location || "",
    link: eventDetails?.link || "",
    writeUp: eventDetails?.writeUp || "",
    non_lab_speakers: eventDetails?.non_lab_speakers || [""],
  };

  const [editEventState, dispatchEditEvent] = useReducer(
    editEventReducer,
    editEventInitialState
  );
  const [editedFields, setEditedFields] = useState<Partial<NewsAndEventsType>>(
    {}
  );

  const [eventImage, setEventImage] = useState<{
    1: string | undefined;
    2: string | undefined;
    3: string | undefined;
  }>({
    1: eventDetails?.images?.[0],
    2: eventDetails?.images?.[1],
    3: eventDetails?.images?.[2],
  });

  const [eventNumber, setEventNumber] = useState<number>(0);
  const eventImages = [
    { image: eventImage[1], alt: "event-image-1" },
    { image: eventImage[2], alt: "event-image-2" },
    { image: eventImage[3], alt: "event-image-3" },
  ];
  const [imageUrl, setImageUrl] = useState<{ 1: string; 2: string; 3: string }>(
    { 1: "", 2: "", 3: "" }
  );

  const dispatch = useDispatch();
  const editEventInputs = [
    {
      placeHolder: "Title",
      type: "text",
      mainType: "title",
      value: editEventState.title,
    },
    {
      placeHolder: "Event date",
      type: "text",
      mainType: "date",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "date";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: new Date(editEventState.date).toISOString().split("T")[0],
    },
    {
      placeHolder: "Time",
      type: "text",
      mainType: "time",
      focus: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "time";
      },
      blur: (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.type = "text";
      },
      value: new Date(editEventState.date)
        .toISOString()
        .split("T")[1]
        .substring(0, 5),
    },
    {
      placeHolder: "Location",
      type: "text",
      mainType: "location",
      value: editEventState.location,
    },
    {
      placeHolder: "Video link",
      type: "text",
      mainType: "link",
      value: editEventState.link,
    },
    {
      placeHolder: "Non lab speakers Seperate with comma ( , )",
      type: "text",
      mainType: "nonLabSpeakers",
      value: editEventState.non_lab_speakers,
    },
  ];

  const inputFileRef = useRef<HTMLInputElement>(null);
  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEventImage({
          ...eventImage,
          [eventNumber + 1]: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  // Track changes in state and edited fields
  const handleInputChange = (mainType: string, value: string) => {
    dispatchEditEvent({ type: mainType, payload: value });
    if (mainType === "nonLabSpeakers") {
      setEditedFields((prev) => ({ ...prev, [mainType]: value.split(",") }));
    } else {
      setEditedFields((prev) => ({ ...prev, [mainType]: value }));
    }
    setShowErrorMessage(false);
  };

  const { cloudName, presetkey } = useCloudinaryUpload();
  async function handleCloudinaryUpload() {
    const updatedImageUrls = { ...imageUrl };
    for (let i = 1 as 1 | 2 | 3; i <= 3; i++) {
      const image = eventImage[i];
      if (image) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", presetkey);
        try {
          const response = await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          );
          updatedImageUrls[i] = response.data.secure_url;
        } catch (error) {
          console.error("Upload failed", error);
        }
      }
    }
    setImageUrl(updatedImageUrls);
    return updatedImageUrls;
  }

  //Submit edit details
  const api = useAuthorizedApi();
  //Compare the initial lab member speakersarray with the updated lab member speakers array
  function arraysAreEqual(
    arr1: LabMemberTypes[] | undefined,
    arr2: LabMemberTypes[]
  ): boolean {
    // Check if lengths are different
    if (arr1?.length !== arr2.length) {
      return true;
    }

    // Compare each object in the array for deep equality
    const areSame = arr1?.every((obj, index) => {
      return JSON.stringify(obj) === JSON.stringify(arr2[index]);
    });

    // If they are the same, return false. Otherwise, return true.
    return !areSame;
  }
  
  async function submitEventDetails(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(
        editEventState.title.length < 3,
        "Title must be at least 3 characters"
      ) ||
      validation(
        editEventState.writeUp.length < 3,
        "About must be at least 3 characters"
      ) ||
      validation(
        speakers.length === 0 && !editEventState.non_lab_speakers,
        "A speaker is required"
      ) ||
      validation(
        editEventState.location.length < 3,
        "Location must be at least 3 characters"
      ) ||
      validation(
        !editEventState.link.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please input a valid link"
      )
    ) {
      return;
    }

    if (
      Object.keys(editedFields).length ||
      arraysAreEqual(eventDetails?.speakers, speakers)
    ) {
      try {
        setIsLoading(true);
        const uploadedImageUrls = await handleCloudinaryUpload();
        const newData = {
          ...editedFields,
          date: editEventState.date,
          speakers: speakers.map((speaker) => ({ id: speaker.id })),
          images: [
            uploadedImageUrls[1],
            uploadedImageUrls[2],
            uploadedImageUrls[3],
          ],
        };

        await api.patch(endpoints.editNewsAndEvent(eventDetails?.id), newData);
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setNotificationMessage("Event edited successfully"));
        dispatch(setErrorNotification(false));
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage(error.response.data.message));
        } else {
          dispatch(setNotificationMessage("Failed to edit event"));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>

      <div className="inputs-card-main-container">
        <div className="inputs-card-header">Edit Event</div>
        <div className="inputs-card-form-inputs-container">
          <div className="inputs-card-images-container">
            {eventImages.map((image, index) => (
              <div
                className="inputs-card-image-main-container"
                key={index}
                onClick={() => setEventNumber(index)}
              >
                <div className="inputs-card-image">
                  <img
                    src={image.image}
                    alt={"lab-member"}
                    style={{
                      height: "190px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  className="input-camera-button"
                  style={{ right: "9rem", bottom: "0rem" }}
                >
                  <button onClick={() => inputFileRef.current?.click()}>
                    <img src={camera} alt="camera" />
                  </button>
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  onChange={handleImageChange}
                  accept="image/jpg, image/jpeg, image/png, image/svg"
                />
              </div>
            ))}
          </div>
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEventDetails}>
            {editEventInputs.map((input, index) => (
              <input
                placeholder={input.placeHolder}
                type={input.type}
                key={index}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(input.mainType, event.target.value)
                }
                onFocus={input.focus}
                onBlur={input.blur}
                value={input.value}
              />
            ))}
            <SelectFields
              selectCaption="Select Speakers"
              participantsArray={speakers}
              setParticipantsArray={setSpeakers}
              sponsorsOrMembersArray="lab-members"
            />
            <textarea
              placeholder="About"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleInputChange("writeUp", event.target.value);
              }}
              value={editEventState.writeUp}
            />
            {isLoading ? (
              <button className="add-button">Saving...</button>
            ) : (
              <button className="add-button">Save</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditEvent;
