import "../styles/ResearchThemes.scss";
import IoTImage from "../../../assets/images/internet-of-things.png";
import industrialIoTImage from "../../../assets/images/industrial-automation.png";
import distributedImage from "../../../assets/images/distributed-systems.png";

function ResearchThemes() {
  const researchThemes = [
    {
      image: IoTImage,
      title: "Communications for the Internet of Things",
      alt: "IoT",
      description:
        "IoT communications facilitate data exchange between devices, driving IoT's potential. Ongoing research targets challenges like low-power, reliable communication, and support for diverse devices. The aim is to advance communication technologies and standards for seamless interoperability.",
    },
    {
      image: industrialIoTImage,
      title: "The Internet of Things and Industrial Automation",
      alt: "Iot",
      description:
        "IoT revolutionizes industrial automation by enabling real-time monitoring, safety enhancements, and increased efficiency. DIPPER Lab conducts pioneering research on IoT-based systems, focusing on sensors, gateways, and cloud platforms for data management.",
    },
    {
      image: distributedImage,
      title: "Distributed Systems and Edge Computing",
      alt: "Distributed-systems",
      description:
        "Distributed systems and edge computing offer speed, reduced latency, and improved security. They efficiently process IoT data without centralizing it. However, challenges like interoperability and scalability need addressing. DIPPER Lab tackles these issues through innovative tech and ongoing research",
    },
  ];
  return (
    <div className="research-themes-main-container">
      <div className="research-themes-header">
        <h2>Research Themes</h2>
      </div>
      <div className="research-themes">
        {researchThemes.map((researchTheme, index) => {
          return (
            <div className="research-theme" key={index}>
              <div className="research-theme-image">
                <img src={researchTheme.image} alt={researchTheme.alt} />
              </div>
              <div className="research-theme-description">
                <h3>{researchTheme.title}</h3>
                <p>{researchTheme.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ResearchThemes;
