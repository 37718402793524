import logo from "../../../assets/images/dipper-lab-logo.png";
import "../styles/AdminForgotPassword.scss";
import { useEffect, useState } from "react";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import Notification from "../../../components/Notification";
import { useSelector, useDispatch } from "react-redux";
import { authenticationApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { AnimatePresence } from "framer-motion";
import { RootState } from "../../../types/GlobalTypes";
import {
  setShowNotification,
  setNotificationMessage,
  setErrorNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  //inport validation function
  const { validation, showErrorMessage, errorMessage, setShowErrorMessage } =
    useValidationHandler();

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  async function submitEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(
        !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        "Please enter a valid email address"
      )
    ) {
      return;
    }

    try {
      setIsLoading(true);
      await authenticationApi.post(endpoints.adminForgotPassword, {
        email: email,
      });
      setStep(2);
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setNotificationMessage("A code has been sent to your email"));
      dispatch(setErrorNotification(false));
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else if (error.response) {
        dispatch(
          setNotificationMessage("Reset Password failed. Please try again")
        );
      }
    }
  }

  async function submitCode(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(code.length < 6, "Please enter a valid 6-digit code") ||
      validation(password.length < 1, "Please enter a password")
    ) {
      return;
    }

    try {
      setIsLoading(true);
      await authenticationApi.patch(endpoints.adminResetPassword, {
        email: email,
        OTP: code,
        password: password,
      });
      setStep(1);
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setNotificationMessage("Password reset successfully"));
      dispatch(setErrorNotification(false));
      navigate("/admin");
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else if (error.response) {
        dispatch(
          setNotificationMessage(
            "Reset Password failed. Please make sure your code is correct"
          )
        );
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            errorNotification={errorNotification}
            message={notificationMessage}
          />
        )}
      </AnimatePresence>
      <div className="forgot-password-main-container">
        <div className="logo">
          <img src={logo} alt="dipper-lab-logo" />
        </div>
        {showErrorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <div className="forgot-password-header">
          <p>Forgot Password</p>
        </div>
        <div className="forgot-password-form">
          <form onSubmit={step === 1 ? submitEmail : submitCode}>
            <input
              type="text"
              placeholder="Enter your email.."
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setShowErrorMessage(false);
              }}
            />
            {step === 2 && (
              <>
                <input
                  type="text"
                  placeholder="Enter your code..."
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setShowErrorMessage(false);
                  }}
                />
                <input
                  type="password"
                  placeholder="Enter new password..."
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setShowErrorMessage(false);
                  }}
                />
              </>
            )}
            <button type="submit">
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
