import "../styles/OurPartners.scss";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import Modal from "../../../components/Modal";
import EditSponsor from "../../Admin/components/EditSponsor";
import Delete from "../../Admin/components/Delete";
import { useQuery } from "react-query";
import { authenticationApi, useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import {
  setShowNotification,
  setErrorNotification,
  setNotificationMessage,
} from "../../../store/reducers/AdminNotificationReducer";

export type SponsorTypes = {
  id: string;
  image: string;
  name: string;
  url: string;
};

function OurPartners() {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );

  //Partner Functions
  const [openEditSponsor, setOpenEditSponser] = useState<boolean>(false);
  const [deleteSponsor, setDeleteSponsor] = useState<boolean>(false);

  //Sponsor states
  const [sponsorState, setSponsorStates] = useState<{
    details: SponsorTypes | null;
    main: SponsorTypes[];
  }>({
    details: null,
    main: [],
  });

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //Fetching sponsor details
  const api = useAuthorizedApi();
  const { isFetching, refetch } = useQuery(
    "sponsors",
    () => {
      return authenticationApi.get(endpoints.getSponsors);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setSponsorStates({ ...sponsorState, main: response.data.sponsors });
      },
    }
  );

  //Delete sponsor
  async function submitdeletedSponsor(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();

    try {
      setDeleteLoading(true);
      await api.delete(endpoints.deleteSponsor(sponsorState.details?.id));
      setDeleteLoading(false);
      setDeleteSponsor(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${sponsorState.details?.name} deleted successfully`
        )
      );
      refetch();
    } catch (error: any) {
      setDeleteLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else {
        dispatch(setNotificationMessage("Failed to delete sponsor"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      {openEditSponsor && (
        <Modal onClose={() => setOpenEditSponser(false)}>
          <EditSponsor sponsorDetails={sponsorState.details} />
        </Modal>
      )}
      {deleteSponsor && (
        <Modal onClose={() => setDeleteSponsor(false)}>
          <Delete
            close={() => setDeleteSponsor(false)}
            deleteFunction={submitdeletedSponsor}
            isLoading={deleteLoading}
            name={sponsorState.details?.name}
            type="delete"
          />
        </Modal>
      )}
      <div className="our-partners-main-container">
        <div className="our-partners-header">
          <h2>Our Partners</h2>
        </div>
        {isFetching ? (
          <>
            <div className="our-partners-images">
              <>
                {[0, 1, 2, 3].map((i) => (
                  <div className="skeletal-dummy-image" key={i} />
                ))}
              </>
            </div>
          </>
        ) : (
          <>
            {sponsorState.main && sponsorState.main.length > 0 ? (
              <>
                <div className="our-partners-images">
                  {sponsorState.main.map((sponsor) => {
                    return (
                      <div
                        className="our-partners-container"
                        key={sponsor.id}
                        onClick={() =>
                          setSponsorStates({
                            ...sponsorState,
                            details: sponsor,
                          })
                        }
                      >
                        <Link to={sponsor.url} target="_blank">
                          <img
                            src={sponsor.image}
                            alt={sponsor.name}
                            loading="lazy"
                          />
                        </Link>
                        {isLoggedIn && (
                          <div className="partners-buttons">
                            <button onClick={() => setOpenEditSponser(true)}>
                              Edit
                            </button>
                            <button onClick={() => setDeleteSponsor(true)}>
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="empty-message">
                  <p>No sponsors available</p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default OurPartners;
