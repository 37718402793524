import "../styles/Objectives.scss";
import researchImage from "../../../assets/images/futuristic-smart-city-with-5g-global-network-technology.png";
import exploreImage from "../../../assets/images/medium-shot-man-wearing-vr-glasses.png";
import peopleMap from "../../../assets/images/People-map.png";

function Objectives() {
  const objectives = [
    {
      title: "Advancing Decentralized IoT Security in African Industries",
      description:
        "To conduct cutting-edge research on the integration of decentralized ledger technologies with IoT and edge intelligence to enhance security, and privacy in industrial areas such as agriculture and healthcare, with a focus on enhancing technology in Africa.",
      image: researchImage,
    },
    {
      title: "Exploring Blockchain-IoT Synergies Across Industries",
      description:
        "To explore new applications of blockchain and IoT in various domains, such as agriculture, industry 4.0, and healthcare, and to contribute to their development and deployment",
      image: exploreImage,
    },
    {
      title:
        "Fostering Academic-Industry Collaboration for Blockchain and IoT Innovation",
      description:
        "To collaborate with universities and research institutions to exchange ideas and resources, produce high-quality research, and promote innovation and commercialization in decentralized ledger technologies, IoT, and edge intelligence.",
      image: peopleMap,
    },
  ];
  return (
    <div className="objectives-main-container">
      <div className="objectives-header">
        <h2>Objectives</h2>
      </div>
      <div className="objectives-container">
        {objectives.map((objective, index) => {
          const objectiveClassNames: string =
            index % 2 === 0
              ? "objective-container"
              : "objective-container-reverse";
          return (
            <div className={objectiveClassNames} key={index}>
              <div className="objective-description">
                <h2 className="objective-number">{`0${index + 1}`}</h2>
                <h3 className="objective-title">{objective.title}</h3>
                <p>{objective.description}</p>
              </div>
              <div className="objective-image">
                <img
                  src={objective.image}
                  alt={objective.title}
                  loading="lazy"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Objectives;
