import { useDispatch, useSelector } from "react-redux";
import logo from "../../../assets/images/dipper-lab-logo.png";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import "../styles/AdminRegister.scss";
import { useState, useEffect } from "react";
import { RootState } from "../../../types/GlobalTypes";
import Notification from "../../../components/Notification";
import { authenticationApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import {
  setNotificationMessage,
  setShowNotification,
  setErrorNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import { AnimatePresence } from "framer-motion";
import { setToken } from "../../../store/reducers/AuthReducer";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn } from "../../../store/reducers/AuthReducer";

function AdminRegister() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();
  const dispatch = useDispatch();

  //Input fields
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  //Notifications
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  //authentication
  const navigate = useNavigate();

  async function registerAdmin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validation(
        !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        "Please type a valid email"
      ) ||
      validation(
        password.length < 6,
        "Password should be at least 6 characters"
      ) ||
      validation(password !== confirmPassword, "Passwords do not match")
    ) {
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    try {
      setIsLoading(true);
      const response = await authenticationApi.post(
        endpoints.adminRegister,
        data
      );
      setIsLoading(false);
      localStorage.setItem("token", response.data.token);
      dispatch(setToken(response.data.token));
      if (localStorage.getItem("token") === response.data.token) {
        dispatch(setIsLoggedIn(true));
      }
      navigate("/");
    } catch (error: any) {
      setIsLoading(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(true));
      if (error.response.data.message) {
        dispatch(setNotificationMessage(error.response.data.message));
      } else if (error.response) {
        dispatch(setNotificationMessage("Failed to register admin"));
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <div className="admin-register-page-main">
        <AnimatePresence>
          {showNotification && (
            <Notification
              errorNotification={errorNotification}
              message={notificationMessage}
            />
          )}
        </AnimatePresence>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        {showErrorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <div className="admin-register-header">
          <span>Admin Register</span>
          <span>Please enter your details to register</span>
        </div>
        <div className="admin-register-form">
          <form onSubmit={registerAdmin}>
            <input
              type="email"
              placeholder="Enter your email..."
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                setShowErrorMessage(false);
              }}
            />
            <input
              type="password"
              placeholder="Enter your password..."
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
                setShowErrorMessage(false);
              }}
            />
            <input
              type="password"
              placeholder="Confirm your password..."
              value={confirmPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value);
                setShowErrorMessage(false);
              }}
            />
            {isLoading ? (
              <button>Registering...</button>
            ) : (
              <button>Register</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminRegister;
