import { useState } from "react";

export function useValidationHandler() {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  function validation(condition: boolean, message: string) {
    if (condition) {
      setShowErrorMessage(true);
      setErrorMessage(message);
      return true;
    }
    return false;
  }

  return { errorMessage, showErrorMessage, validation, setShowErrorMessage };
}
