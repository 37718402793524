import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import Notification from "../../../components/Notification";
import { AnimatePresence } from "framer-motion";
import { useCloudinaryUpload } from "../../../helpers/CloudinarySetup";
import { useValidationHandler } from "../../../helpers/ValidationHandler";
import camera from "../../../assets/icons/camera.png";
import ErrorMessage from "../../../components/ErrorMessage";
import "../styles/AdminComponentStyles.scss";
import { SponsorTypes } from "../../Home Page/components/OurPartners";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import {
  setShowNotification,
  setErrorNotification,
  setNotificationMessage,
} from "../../../store/reducers/AdminNotificationReducer";
import { useEditedData } from "../../../helpers/EdittedData";

interface Props {
  sponsorDetails: SponsorTypes | null;
}
function EditSponsor({ sponsorDetails }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sponsorName, setSponsorName] = useState<string>(
    sponsorDetails?.name || ""
  );
  const [sponsorUrl, setSponsorUrl] = useState<string>(
    sponsorDetails?.url || ""
  );

  //Cloudinary image upload
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { handleCloudinaryUpload, handleImageChange, image, setImage } =
    useCloudinaryUpload();

  //validation handler
  const { showErrorMessage, errorMessage, setShowErrorMessage, validation } =
    useValidationHandler();

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );

  const editSponsorInputs = [
    {
      placeHolder: "Name",
      type: "text",
      value: sponsorName,
      onChangeFunction(event: React.ChangeEvent<HTMLInputElement>) {
        setSponsorName(event.target.value);
      },
    },
    {
      placeHolder: "Url",
      type: "text",
      value: sponsorUrl,
      onChangeFunction(event: React.ChangeEvent<HTMLInputElement>) {
        setSponsorUrl(event.target.value);
      },
    },
  ];

  //submit eddited sponsor details
  const api = useAuthorizedApi();
  const originalData: SponsorTypes = {
    id: sponsorDetails?.id || "",
    name: sponsorDetails?.name || "",
    image: sponsorDetails?.image || "",
    url: sponsorDetails?.url || "",
  };

  const newData: Partial<SponsorTypes> = {
    id: sponsorDetails?.id || "", // Assuming id is not changing
    name: sponsorName,
    image: image,
    url: sponsorUrl,
  };

  // Initialize image state with the existing sponsor image
  useEffect(() => {
    if (sponsorDetails?.image) {
      setImage(sponsorDetails.image);
    }
  }, [sponsorDetails, setImage]);

  const { getChangedData } = useEditedData();
  const editedData = getChangedData(originalData, newData);

  async function submitEditSponsorDetails(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    if (
      validation(sponsorName.length === 0, "Please type a sponsor name ") ||
      validation(
        !sponsorUrl.match(/^(ftp|http|https):\/\/[^ "]+$/),
        "Please enter a valid url"
      )
    ) {
      return;
    }

    if (Object.keys(editedData).length > 0) {
      try {
        setIsLoading(true);
        let imageUrl: string | null = originalData.image;
        if (image !== originalData.image) {
          imageUrl = await handleCloudinaryUpload(image);
          if (imageUrl) {
            editedData.image = imageUrl;
          }
        }
        await api.patch(endpoints.editSponsor(sponsorDetails?.id), editedData);
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(false));
        dispatch(setNotificationMessage("Sponsor edited successfully"));
      } catch (error: any) {
        setIsLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage(error.response.data.message));
        } else {
          dispatch(setNotificationMessage("Failed to edit sponsor"));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);
  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      <div className="edit-sponsor-main-container">
        <div className="edit-sponsor-card-header">Edit Sponsor</div>
        <div className="inputs-card-images-container">
          <div className="inputs-card-image-main-container">
            <div className="inputs-card-image">
              <img
                src={image || sponsorDetails?.image}
                alt={"lab-member"}
                style={{
                  height: "200px",
                  width: "300px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className="input-camera-button"
              style={{ right: "9.5rem", bottom: "0rem" }}
            >
              <button
                onClick={() => {
                  inputFileRef.current?.click();
                  setShowErrorMessage(false);
                }}
              >
                <img src={camera} alt="camera" />
              </button>
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputFileRef}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleImageChange(event)
              }
              accept="image/png, image/svg, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div className="edit-sponsor-card-form-inputs-container">
          {showErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <form onSubmit={submitEditSponsorDetails}>
            {editSponsorInputs.map((sponsor, index) => {
              return (
                <input
                  type={sponsor.type}
                  placeholder={sponsor.placeHolder}
                  value={sponsor.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    sponsor.onChangeFunction(event);
                    setShowErrorMessage(false);
                  }}
                  key={index}
                />
              );
            })}
            {isLoading ? <button>Saving...</button> : <button>Save</button>}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditSponsor;
