import axios from "axios";
import { useState } from "react";
import testImage from "../assets/images/default-image.jpg";
export function useCloudinaryUpload() {
  //environment variables for production
  const cloudName: string = window._env_.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const presetkey: string = window._env_.REACT_APP_CLOUDINARY_PRESET_KEY;

  //variables for testing
  // const cloudName: string = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  // const presetkey: string = process.env.REACT_APP_CLOUDINARY_PRESET_KEY;

  const [image, setImage] = useState<string>(testImage);

  //Handle viewing of image before uploading
  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the uploaded image's base64 to state
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  //handle cloudinary upload
  async function handleCloudinaryUpload(image: string): Promise<string | null> {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", presetkey);

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      return null;
    }
  }

  return {
    handleCloudinaryUpload,
    cloudName,
    presetkey,
    image,
    handleImageChange,
    setImage,
  };
}
