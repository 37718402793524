import "../styles/Events.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/GlobalTypes";
import Modal from "../../../components/Modal";
import EditEvent from "../../Admin/components/EditEvent";
import Delete from "../../Admin/components/Delete";
import { useQuery } from "react-query";
import { useAuthorizedApi } from "../../../services/Axios";
import { endpoints } from "../../../services/Endpoints";
import { LabMemberTypes } from "../../Lab Members Page/components/LabMembers";
import {
  setNotificationMessage,
  setErrorNotification,
  setShowNotification,
} from "../../../store/reducers/AdminNotificationReducer";
import Notification from "../../../components/Notification";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
export type NewsAndEventsType = {
  id?: string;
  date: Date | string;
  images?: string[];
  link: string;
  location: string;
  non_lab_speakers: string[] | string;
  speakers?: LabMemberTypes[];
  title: string;
  writeUp: string;
  time?: string;
};
function Events() {
  const dispatch = useDispatch();
  const [newsAndEventsState, setNewsAndEventsState] = useState<{
    main: NewsAndEventsType[];
    details: NewsAndEventsType | null;
  }>({
    main: [],
    details: null,
  });
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const [openEditEvent, setOpenEditEvent] = useState<boolean>(false);
  const [openDeleteEvent, setOpenDeleteEvent] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  //Fetching events
  const api = useAuthorizedApi();
  const { isFetching, refetch } = useQuery(
    "events",
    () => {
      return api.get(endpoints.getNewsAndEvents);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setNewsAndEventsState({
          ...newsAndEventsState,
          main: response.data.events,
        });
      },
    }
  );

  //notification states
  const showNotification = useSelector(
    (state: RootState) => state.adminNotifications.showNotification
  );
  const errorNotification = useSelector(
    (state: RootState) => state.adminNotifications.errorNotification
  );
  const notificationMessage = useSelector(
    (state: RootState) => state.adminNotifications.notificationMessage
  );
  async function deleteEvent(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    try {
      setIsDeleteLoading(true);
      await api.delete(endpoints.deleteEvent(newsAndEventsState.details?.id));
      setIsDeleteLoading(false);
      setOpenDeleteEvent(false);
      dispatch(setShowNotification(true));
      dispatch(setErrorNotification(false));
      dispatch(
        setNotificationMessage(
          `${newsAndEventsState.details?.title} deleted successfully`
        )
      );
      refetch();
    } catch (error: any) {
      if (error) {
        setIsDeleteLoading(false);
        dispatch(setShowNotification(true));
        dispatch(setErrorNotification(true));
        if (error.response) {
          dispatch(setNotificationMessage(error.response.data.message));
        } else {
          dispatch(setNotificationMessage("Failed to delete event"));
        }
      }
    }
  }

  //Close notification message
  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowNotification(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage}
            errorNotification={errorNotification}
          />
        )}
      </AnimatePresence>
      {openEditEvent && (
        <Modal onClose={() => setOpenEditEvent(false)}>
          <EditEvent eventDetails={newsAndEventsState.details} />
        </Modal>
      )}
      {openDeleteEvent && (
        <Modal onClose={() => setOpenDeleteEvent(false)}>
          <Delete
            deleteFunction={deleteEvent}
            isLoading={isDeleteLoading}
            close={() => setOpenDeleteEvent(false)}
            name={newsAndEventsState.details?.title}
            type="delete"
          />
        </Modal>
      )}
      <div className="events-main-container">
        <div className="events-main-header">
          <h1>News & Events</h1>
        </div>
        <div className="events-container">
          {isFetching ? (
            <>
              {[0, 1, 2, 3, 4].map((_, index) => {
                return (
                  <div className="skeletal-loading-main-container" key={index}>
                    <div className="skeletal-dummy-image"></div>
                    <div className="skeletal-dummy-title"></div>
                    <div className="skeletal-dummy-description">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {newsAndEventsState.main && newsAndEventsState.main.length > 0 ? (
                <>
                  {newsAndEventsState.main.map((events, index) => {
                    const formattedDate = new Date(events.date).toDateString();
                    const shortenedDescription = events.writeUp
                      .trim()
                      .substring(0, 250);
                    return (
                      <AnimatePresence key={index}>
                        <motion.div
                          className="event"
                          onClick={() =>
                            setNewsAndEventsState({
                              ...newsAndEventsState,
                              details: events,
                            })
                          }
                        >
                          <img
                            src={events.images?.[0]}
                            alt="event"
                            loading="lazy"
                          />
                          <div className="event-info">
                            <span>Event</span>
                            <span>{formattedDate}</span>
                          </div>
                          <h4 className="event-header">{events.title}</h4>
                          <p className="event-description">
                            {shortenedDescription + "..."}
                            <Link to={`/events/details/${events.id}`}>
                              Read More
                            </Link>
                          </p>
                          {isLoggedIn && (
                            <div className="add-event-button">
                              <button onClick={() => setOpenEditEvent(true)}>
                                Edit
                              </button>
                              <button onClick={() => setOpenDeleteEvent(true)}>
                                Delete
                              </button>
                            </div>
                          )}
                        </motion.div>
                      </AnimatePresence>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="empty-message">
                    <p>No events available</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Events;
